import { useState, Fragment } from "react"
import { useQuery } from "react-query"
import { useLocation } from "react-router-dom"
import Websocket from "react-websocket"

import { CircularProgress, Grid } from "@mui/material"

import { DateRangePicker, Typography, Select, NotificationUtils } from "@synapse-analytics/synapse-ui"
import moment from "moment"
import { NumberParam, useQueryParam } from "use-query-params"
import { shallow } from "zustand/shallow"

import { VisionAPI } from "../../../API/VisionAPI"
import Placeholder from "../../../components/Placeholder"
import TogglePages from "../../../components/TogglePages"
import { useDateQuery } from "../../../hooks/useDateQuery"
import { storeApi, useBranchesStore } from "../../../store"
import HeatmapLogs from "./HeatmapLogs"
import LiveHeatmap from "./LiveHeatmap"

import styles from "./Heatmap.module.scss"

const now = moment()

function Heatmap() {
  const location = useLocation()
  const isLive = location?.pathname?.includes("analytics-heatmap-live")
  const [selectedFloor, setSelectedFloor] = useQueryParam("floor", NumberParam)

  const [floorLoading, setFloorLoading] = useState(false)
  const [startDate, setStartDate, endDate, setEndDate] = useDateQuery({
    isHidden: isLive,
  })
  const [selectedBranch] = useBranchesStore((state) => [state.selectedBranch], shallow)

  const [apiWsProtocol, apiHostname, apiPort] = [
    storeApi.getState().apiWsProtocol,
    storeApi.getState().apiHostname,
    storeApi.getState().apiPort,
  ]

  const handleFloorChange = (e) => {
    const value = e.target.value
    if (isLive) {
      setTimeout(() => {
        refetchLiveHeatmap()
      }, 300)
    } else {
      setTimeout(() => {
        refetchVersions()
      }, 300)
    }
    setSelectedFloor(value)
  }

  // Fetch floor plans
  const { data: floors, isFetching: isFloorsLoading } = useQuery(
    ["fetchFloors", selectedBranch],
    ({ queryKey }) => VisionAPI.fetchFloorPlans({ branch: queryKey[1] }),
    {
      enabled: !!selectedBranch,
    }
  )

  const getFloorLoadingState = (loading) => {
    setFloorLoading(loading)
  }

  // Fetch floor plan versions
  const {
    data: floorPlanVersions,
    isFetching: loadingFloorVersions,
    refetch: refetchVersions,
  } = useQuery(
    [
      "fetchFloorPlanVersions",
      selectedFloor,
      isLive ? now?.toISOString() : startDate?.toISOString(),
      endDate?.toISOString(),
    ],
    async ({ queryKey }) =>
      VisionAPI.fetchFloorPlanVersions({ floor_id: queryKey[1], from_dt: queryKey[2], to_dt: queryKey[3] }),
    {
      onSuccess: (data) => {
        if (data && data.length < 1) {
          NotificationUtils.toast("No Floor versions to display", {
            severity: "warning",
          })
        }
      },
      enabled: !isFloorsLoading && !isLive && !!selectedFloor && !!endDate,
    }
  )

  // fetch live heatmap floor [on change]
  const {
    data: liveHeatmapFloor,
    isFetching,
    refetch: refetchLiveHeatmap,
  } = useQuery(["fetchLiveHeatmapFloor", selectedFloor], ({ queryKey }) => VisionAPI.fetchFloorPlan(queryKey[1]), {
    enabled: isLive && !!selectedFloor,
  })

  // life heatmap section
  const LiveHeatmapSection = () => {
    const [streamData, setStreamData] = useState("")
    const handleStream = (data) => {
      setStreamData(JSON.parse(data))
    }
    return (
      <Fragment>
        {!!selectedFloor && (
          <Websocket
            url={`${apiWsProtocol}//${apiHostname}:${apiPort}/ws/heatmap/live/${selectedFloor}/`}
            onMessage={handleStream}
          />
        )}

        {floors && floors?.length > 0 && !!selectedFloor && liveHeatmapFloor ? (
          streamData && !isFetching ? (
            <LiveHeatmap streamData={streamData} liveHeatmapFloor={liveHeatmapFloor} key={selectedFloor} />
          ) : (
            <Grid
              container={true}
              alignItems="center"
              direction="row"
              justifyContent="center"
              className={styles.loadingWrapper}
            >
              <Grid item alignItems="center">
                <CircularProgress size={70} className={styles.loading} />
              </Grid>
            </Grid>
          )
        ) : (
          !!selectedFloor && <Placeholder isScreenPlaceholder alertMessage="Floor Plan Not Found" openSnackbar />
        )}
      </Fragment>
    )
  }

  const mappedFloors =
    floors && floors?.length > 0
      ? floors.map((floor) => {
          return {
            label: floor.floor_name,
            value: floor.id,
          }
        })
      : []

  return (
    <div className={styles.wrapper}>
      <Typography
        variant="h2-regular"
        tooltip={`Where most visitors tend to go ${isLive ? "live" : "for selected date range"}`}
        tooltipPlacement="right"
        tooltipIconSize={22}
        gutterBottom
        variantColor={2}
      >
        {`Heatmap ${isLive ? "Live" : "History"}`}
      </Typography>
      <div
        className={styles.header}
        style={{
          left: isLive ? "225px" : "210px",
        }}
      >
        <Select
          id="floor-select"
          placeholder="Select Floor"
          value={selectedFloor ?? ""}
          handleChange={handleFloorChange}
          disabled={!endDate}
          loading={isFloorsLoading}
          optionsWithValues={mappedFloors}
          width={235}
          label={!!selectedFloor ? "Selected Floor" : ""}
          variant="filled"
          isNotchedLabel
        />
        {!isLive && (
          <div className={styles.datePicker}>
            <DateRangePicker
              startDate={startDate}
              endDate={endDate}
              disabled={floorLoading}
              onStartDateChange={setStartDate}
              onEndDateChange={setEndDate}
              disableFuture
            />
          </div>
        )}
      </div>

      <TogglePages
        pages={["Live", "History"]}
        firstPage={<LiveHeatmapSection />}
        firstPath="analytics-heatmap-live"
        secondPage={
          <HeatmapLogs
            getFloorLoadingState={getFloorLoadingState}
            floorPlanVersions={floorPlanVersions}
            loadingFloorVersions={loadingFloorVersions}
            startDate={startDate?.format("YYYY-MM-DD")}
            endDate={endDate?.format("YYYY-MM-DD")}
            floorId={selectedFloor}
          />
        }
        secondPath="analytics-heatmap-history"
        liveHistory
        pathsParent="floors-heatmap"
      />
    </div>
  )
}
export default Heatmap
