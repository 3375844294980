import { FC, Fragment, useState } from "react"

import StackedBarChartIcon from "@mui/icons-material/StackedBarChart"
import TableViewIcon from "@mui/icons-material/TableView"
import { Box } from "@mui/material"

import { Typography } from "@synapse-analytics/synapse-ui"

import { ColoredLineGraphData, TableColumn } from "../../types/Custom/Types"
import { getDefaultCheckboxes, getInitialActiveStatsTab } from "../../utils/genericHelpers"
import ButtonSwitch from "../Buttons/ButtonSwitch"
import CategoriesLineGraph from "../graphs/CheckboxesLineGraph"
import CountTable from "../tables/GenericTable"

import styles from "./CheckboxesLineGraphCard.module.scss"

interface GraphProps {
  /**
   * the graph data.
   */
  data: ColoredLineGraphData[]
}

interface TableProps {
  columns: TableColumn[]
  data: any
}

interface Props {
  graphProps: GraphProps
  tableProps?: TableProps
  title: string
  isLoading?: Boolean
  timeGrain?: "day" | "hour"
  isLargeInterval?: boolean
  contentHeight?: number
  ref?: React.MutableRefObject<any>
}

const CheckboxesLineGraphCard: FC<Props> = ({
  title,
  graphProps,
  tableProps,
  isLoading,
  timeGrain,
  isLargeInterval,
  contentHeight = 350,
  ref,
}) => {
  const defaultCheckboxes = getDefaultCheckboxes(graphProps?.data)
  const [activeView, setActiveView] = useState(getInitialActiveStatsTab({ graphProps, tableProps }))

  return (
    <div className={styles.wrapper} ref={ref}>
      <Fragment>
        <div className={styles.header}>
          <Typography variant="h2-bold" variantColor={2}>
            {title}
          </Typography>
          {graphProps && tableProps && (
            <Box
              sx={{
                display: {
                  xs: "none",
                  md: "block",
                },
              }}
            >
              <ButtonSwitch
                activePage={activeView}
                pages={["Graph", "Table"]}
                handleSelectButton={setActiveView}
                disabled={!!isLoading}
                pagesIcons={[<StackedBarChartIcon />, <TableViewIcon />]}
              />
            </Box>
          )}
        </div>

        {activeView === 0 && graphProps ? (
          <CategoriesLineGraph
            data={graphProps?.data}
            isLoading={isLoading}
            graphHeight={contentHeight}
            defaultCheckboxes={defaultCheckboxes}
            timeGrain={timeGrain}
            isLargeInterval={isLargeInterval}
          />
        ) : (
          activeView === 1 &&
          tableProps && (
            <CountTable
              data={tableProps?.data}
              isLoading={!!isLoading}
              columns={tableProps.columns}
              title={title}
              height={contentHeight + 32}
              verticalMargin={1}
            />
          )
        )}
      </Fragment>
    </div>
  )
}
export default CheckboxesLineGraphCard
