import React, { useState, useEffect, FC, Fragment } from "react"
import { useQuery } from "react-query"

import { Grid } from "@mui/material"
import CircularProgress from "@mui/material/CircularProgress"

import { NotificationUtils } from "@synapse-analytics/synapse-ui"
import { Moment } from "moment"

import { VisionAPI } from "../../../API/VisionAPI"
import Placeholder from "../../../components/Placeholder"
import { Mark } from "../../../types/Custom/Interfaces"
import { definitions } from "../../../types/Generated/apiTypes"
import { dayMarks, DynamicHeatmapMarks } from "../../../utils/heatmapUtils"
import HeatmapTimeline from "../components/HeatmapTimeline"

import styles from "./HeatmapContainer.module.scss"

type HeatMapHourly = definitions["HeatMapHourly"]

interface Props {
  startDate: Moment | null
  endDate: Moment | null
  cameraSnapshot: string
  cameraId: number
  timeGrain: "hourly" | "daily"
  loadingSetter: (loadingState: boolean) => void
}
const HeatmapLogs: FC<Props> = ({ startDate, endDate, cameraSnapshot, cameraId, timeGrain, loadingSetter }) => {
  const [marks, setMarks] = useState<Mark[]>(dayMarks)

  useEffect(() => {
    if (startDate && endDate) {
      const dateDiff = endDate.diff(startDate, "days")
      // hourly
      if (timeGrain === "hourly") {
        setMarks(dayMarks)
      } else {
        // daily
        const marks = DynamicHeatmapMarks(dateDiff + 1)
        setMarks(marks)
      }
    }
  }, [startDate, endDate, timeGrain])

  const { data: fetchedHeatmapLogs, isLoading: heatmapLogsLoading } = useQuery<HeatMapHourly[]>(
    ["fetchCamerasHeatmapLogs", cameraId, startDate?.format("YYYY-MM-DD"), endDate?.format("YYYY-MM-DD")],
    ({ queryKey }) =>
      VisionAPI.fetchCameraHeatmapLogs({
        camera_id: queryKey[1] as number,
        from_date: queryKey[2] as string,
        to_date: queryKey[3] as string,
      }),
    {
      onSettled: (data) => {
        if (data && data.length < 1) {
          NotificationUtils.toast("Camera's heatmap logs not found for selected date range", {
            severity: "warning",
          })
        }
      },
      enabled: !!startDate && !!endDate,
    }
  )
  useEffect(() => {
    if (!heatmapLogsLoading) {
      loadingSetter(false)
    } else {
      loadingSetter(true)
    }
    //eslint-disable-next-line
  }, [heatmapLogsLoading])

  return (
    <Fragment>
      {heatmapLogsLoading ? (
        <Grid
          container={true}
          alignItems="center"
          direction="row"
          justifyContent="center"
          className={styles.loadingWrapper}
        >
          <Grid item alignItems="center">
            <CircularProgress size={70} className={styles.loading} />
          </Grid>
        </Grid>
      ) : fetchedHeatmapLogs && fetchedHeatmapLogs.length > 0 ? (
        <HeatmapTimeline
          marks={marks}
          timeGrain={timeGrain}
          heatmapLogs={fetchedHeatmapLogs}
          isCameraHeatmap
          cameraSampleFrame={cameraSnapshot}
        />
      ) : (
        <Placeholder selectionType="history" isScreenPlaceholder />
      )}
    </Fragment>
  )
}
export default HeatmapLogs
