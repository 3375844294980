import React, { useState, Fragment, useEffect } from "react"
import { useInfiniteQuery } from "react-query"

import AddCircleIcon from "@mui/icons-material/AddCircle"
import { CircularProgress } from "@mui/material"
import useMediaQuery from "@mui/material/useMediaQuery"

import ChunkedUploady from "@rpldy/chunked-uploady"
import { Typography, Button } from "@synapse-analytics/synapse-ui"
import { SwiperSlide } from "swiper/react"

import { VisionAPI } from "../../../API/VisionAPI"
import NoFootage from "../../../assets/NoTableLogs.svg?react"
import SwiperContainer from "../../../components/SwiperContainer"
import { PaginatedFootage } from "../../../types/Custom/Interfaces"
import { definitions } from "../../../types/Generated/apiTypes"
import { extractPageFromBackEndPaginationLink } from "../../../utils/genericHelpers"
import UploadFootage from "../UploadFootage/components/UploadFootage"
import FootageCard from "../components/FootageCard"
import FootageContextProvider from "../footageContext/FootageContext"

import styles from "./CameraFootages.module.scss"

type Footages = definitions["CameraFootageRetrieve"]
type Camera = definitions["CameraRetrieveUpdate"]

const CameraFootages = ({ camera }: { camera?: Camera }) => {
  const [limit, setLimit] = useState(5)
  const xLargeScreen = useMediaQuery("(min-width:1900px)")
  const smallScreen = useMediaQuery("(min-width:1400px)")
  const mediumScreen = useMediaQuery("(min-width:1525px)")
  const largeScreen = useMediaQuery("(min-width:1600px)")
  const xSmallScreen = useMediaQuery("(min-width:680px)")
  const [isFootageUploadOpen, setIsFootageUploadOpen] = useState(false)

  useEffect(() => {
    let limit
    if (xLargeScreen) {
      limit = 7
    } else if (largeScreen) {
      limit = 6
    } else if (mediumScreen) {
      limit = 5
    } else if (smallScreen) {
      limit = 4
    } else if (xSmallScreen) {
      limit = 3
    } else {
      limit = 1
    }

    setLimit(limit)
  }, [smallScreen, mediumScreen, largeScreen, xLargeScreen, xSmallScreen])

  const handleCloseFootage = () => {
    setIsFootageUploadOpen(false)
  }

  const {
    data: paginatedFootageData,
    fetchNextPage,
    isLoading: isLoadingFootages,
    isFetchingNextPage,
    isFetchingPreviousPage,
  } = useInfiniteQuery<PaginatedFootage>(
    ["fetchCameraFootages", camera?.id, limit],
    ({ pageParam, queryKey }) =>
      VisionAPI.fetchCameraFootages({ page: pageParam, camera: queryKey[1] as number, limit: queryKey[2] as number }),
    {
      keepPreviousData: true,
      getNextPageParam: (lastPage: PaginatedFootage) => {
        return lastPage?.next ? extractPageFromBackEndPaginationLink(lastPage.next) : undefined
      },
      enabled: !!camera && !!camera?.id,
    }
  )

  const isFetching = isFetchingNextPage || isFetchingPreviousPage

  return (
    <Fragment>
      <div className={styles.header}>
        <Typography variant="h2-bold" variantColor={2} style={{ marginBottom: 12 }}>
          Footage
        </Typography>
        <Button onClick={() => setIsFootageUploadOpen(true)} startIcon={<AddCircleIcon fontSize="small" />}>
          Add new footage
        </Button>
      </div>
      <div>
        {isLoadingFootages ? (
          <CircularProgress size={50} className={styles.loading} style={{ marginTop: 68 }} />
        ) : paginatedFootageData && paginatedFootageData.pages[0].count > 0 ? (
          isLoadingFootages ? (
            <CircularProgress size={80} className={styles.loading} />
          ) : (
            <SwiperContainer
              slidesPerView={limit}
              slidesToScroll={limit}
              fetchNextPage={fetchNextPage}
              isLoading={isFetching}
              count={paginatedFootageData.pages[0].count}
            >
              {paginatedFootageData?.pages.map((page) =>
                page.results.map((footage: Footages, i: number) => (
                  <SwiperSlide key={i}>
                    <FootageCard footage={footage} />
                  </SwiperSlide>
                ))
              )}
            </SwiperContainer>
          )
        ) : (
          <div className={styles.noData}>
            <NoFootage />
            <Typography variant="h2-regular" variantColor={2} style={{ marginTop: 16 }}>
              No footage yet
            </Typography>
          </div>
        )}
      </div>
      <ChunkedUploady
        chunkSize={50000000} //50MB
        retries={5}
        parallel={5}
        accept="video/*"
      >
        <FootageContextProvider>
          <UploadFootage handleClose={handleCloseFootage} open={isFootageUploadOpen} camera={camera} />
        </FootageContextProvider>
      </ChunkedUploady>
    </Fragment>
  )
}

export default CameraFootages
