import React, { useState, useEffect, ChangeEvent } from "react"
import { useQuery } from "react-query"
import { useNavigate } from "react-router-dom"

import ExpandLessIcon from "@mui/icons-material/ExpandLess"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import GetAppIcon from "@mui/icons-material/GetApp"
import { Grid } from "@mui/material"

import { Chip, Button, Typography, Switch } from "@synapse-analytics/synapse-ui"
import { DateRangePicker } from "@synapse-analytics/synapse-ui"
import { AxiosError } from "axios"
import { format } from "date-fns"
import intervalToDuration from "date-fns/intervalToDuration"
import { CsvBuilder } from "filefy"
import { ArrayParam, BooleanParam, StringParam, useQueryParams, withDefault } from "use-query-params"
import { shallow } from "zustand/shallow"

import { VisionAPI } from "../../API/VisionAPI"
import Search from "../../components/Search"
import EntitiesTable from "../../components/tables/GenericTable"
import { useDateQuery } from "../../hooks/useDateQuery"
import { routes } from "../../routes/routes"
import { useBranchesStore } from "../../store"
import { CategorizedEntities, TableColumn } from "../../types/Custom/Types"
import { definitions } from "../../types/Generated/apiTypes"
import { categorizeEntities } from "../../utils/shopUtils"

import styles from "./TenantsList.module.scss"

type Category = definitions["Category"]

type TableLogs = definitions["ShopsAnalytics"]

type shopData = definitions["ShopsAnalytics"] & {
  categoryFairShare: number
}

const sectionHeight = "auto"

const TenantsList = () => {
  const navigate = useNavigate()

  const [startDate, setStartDate, endDate, setEndDate] = useDateQuery()
  const [query, setQuery] = useQueryParams({
    searchVal: withDefault(StringParam, ""),
    shouldIncludeStaff: withDefault(BooleanParam, false),
    selectedCategories: withDefault(ArrayParam, []),
  })

  const [tableData, setTableData] = useState<shopData[]>([])
  const [timeGrain, setTimeGrain] = useState<"hour" | "day" | null>(null)
  const [categorizedEntities, setCategorizedEntities] = useState<CategorizedEntities>()
  const [quickFilterOpen, setQuickFilterOpen] = useState(query.selectedCategories?.length > 0 || false)
  const [selectedBranch] = useBranchesStore(
    (state: { selectedBranch: number | null }) => [state.selectedBranch],
    shallow
  )

  useEffect(() => {
    if (endDate !== null && startDate !== null) {
      const interval = intervalToDuration({
        start: startDate!.toDate(),
        end: endDate!.toDate(),
      })
      if (interval.days! > 1 || interval.months! >= 1) {
        setTimeGrain("day")
      } else {
        setTimeGrain("hour")
      }
    }
  }, [startDate, endDate, timeGrain])

  const handleSearch = (value: string) => {
    setQuery({ searchVal: value })
  }

  const { data: categories, isLoading: categoriesLoading } = useQuery<Category[], AxiosError>(
    "fetchCategories",
    VisionAPI.fetchCategories
  )

  const { data: tableLogs, isLoading: tableLogsLoading } = useQuery<TableLogs[]>(
    [
      "fetchShopsTableLogs",
      startDate?.format("YYYY-MM-DD"),
      endDate?.format("YYYY-MM-DD"),
      query.shouldIncludeStaff,
      selectedBranch,
    ],
    ({ queryKey }) =>
      VisionAPI.fetchShopsTableLogs({
        from_date: queryKey[1] as string,
        to_date: queryKey[2] as string,
        include_staff: queryKey[3] as boolean,
        branch: queryKey[4] as number,
      }),
    {
      enabled: !!startDate && !!endDate,
    }
  )

  useEffect(() => {
    if (tableLogs && !tableLogsLoading) {
      setCategorizedEntities(categorizeEntities(tableLogs))
    }
  }, [tableLogs, tableLogsLoading])

  useEffect(() => {
    if (tableLogs && !tableLogsLoading && categorizedEntities) {
      const fairShareAddedData = tableLogs.map((element) => {
        return {
          ...element,
          categoryFairShare:
            element.area && element.category
              ? parseFloat(
                  (
                    element.in_count /
                    categorizedEntities[element.category]?.totalCount /
                    (element.area / categorizedEntities[element.category]?.totalArea)
                  ).toFixed(2)
                )
              : 0,
        }
      })
      setTableData(fairShareAddedData)
    }
  }, [categorizedEntities, tableLogs, tableLogsLoading])

  const isLoading = [tableLogsLoading, categoriesLoading].some((element) => element === true)

  // handling clicking on one of the categories filters
  const handleFilterByCategory = (category: string) => {
    const categories = [...query.selectedCategories] as string[]

    //checking weather categories array contain that category
    if (!categories.includes(category)) {
      categories.push(category) //adding to array because value doesn't exists
    } else {
      categories.splice(categories.indexOf(category), 1) //deleting
    }
    setQuery({ selectedCategories: [...categories] })
  }

  const filteredTableData = tableData.filter((element) => {
    const matchesCategory =
      query.selectedCategories.length === 0 || (query.selectedCategories as string[]).includes(element.category)
    const matchesSearch = element.name.toLowerCase().includes(query.searchVal.toLowerCase())
    return matchesCategory && matchesSearch
  })

  const handleExportCSV = () => {
    const builder = new CsvBuilder(
      `Shops Table - From ` +
        (startDate ? format(new Date(startDate!.toDate()), "do MMM yyyy") : "Picked Date") +
        (endDate !== null ? " To " + format(new Date(endDate.toDate()), "do MMM yyyy") : "")
    )
    let csvFormattedData: string[][] = [[]]
    csvFormattedData.pop()
    filteredTableData.map((row) =>
      csvFormattedData.push([
        row.name,
        row.category ? row.category : "-",
        isNaN(row.categoryFairShare) ? "No Data" : row.categoryFairShare.toString(),
        row.subcategory ? row.subcategory : "-",
        row.in_count !== undefined ? row.in_count.toString() : "No Data",
        row.area ? row.area.toString() + " m²" : "-",
        !!row.occupancy_rate ? row.occupancy_rate.toString() + " Person / m²" : "No Data",
        !row.avg_dwelling_duration ? "No Data" : (row?.avg_dwelling_duration / 60).toFixed(2).toString() + " Minutes",
      ])
    )
    builder
      .setColumns([
        "Shop Name",
        "Category",
        "Fair Share",
        "Sub Category",
        "Total Count",
        "Area",
        "Occupancy Rate",
        "Average Dwelling",
      ])
      .addRows(csvFormattedData)
      .exportFile()
  }

  const handleSwitchStaffInclusion = (event: ChangeEvent<HTMLInputElement>) => {
    setQuery({ shouldIncludeStaff: event.target.checked })
  }

  const tableColumns: TableColumn[] = [
    {
      title: (
        <Typography variant="a" align="left">
          Tenant Name
        </Typography>
      ),
      field: "name",
      render: (rowData: shopData) => {
        return (
          <Typography
            className={styles.tenantName}
            variant="p"
            color="important"
            variantColor={2}
            onClick={() =>
              navigate(`/${routes?.tenantsList}/${routes?.tenantDetails}/${rowData.entity_id}`, {
                state: {
                  startDate: startDate?.toDate(),
                  endDate: endDate?.toDate(),
                  tenantId: rowData.entity_id,
                  tenantName: rowData.name,
                  area: rowData.area,
                  category: rowData.category,
                  subcategory: rowData.subcategory,
                },
              })
            }
          >
            {rowData.name}
          </Typography>
        )
      },
    },
    {
      title: (
        <Typography variant="a" align="left">
          Category
        </Typography>
      ),
      field: "category",
      searchable: false,
      headerStyle: { textAlign: "left" },
      cellStyle: { textAlign: "left" },
      render: (rowData: shopData) => (
        <Typography
          title={rowData.category ? rowData.category : "category"}
          color={!rowData.category ? "base" : "orange"}
          variantColor={2}
          align="left"
          variant="p"
        >
          {!!rowData.category
            ? rowData.category?.length >= 18
              ? rowData.category.slice(0, 15) + ".."
              : rowData.category
            : " ــــ "}
        </Typography>
      ),
    },
    {
      title: (
        <Typography variant="a" align="left">
          Sub-category
        </Typography>
      ),
      field: "subcategory",
      searchable: false,
      headerStyle: { textAlign: "left" },
      cellStyle: { textAlign: "left" },
      render: (rowData: shopData) => (
        <Typography
          title={rowData.subcategory ? rowData.subcategory : "sub-category"}
          color={!rowData.subcategory ? "base" : "pink"}
          variantColor={2}
          align="left"
          variant="p"
        >
          {!!rowData.subcategory
            ? rowData.subcategory?.length >= 18
              ? rowData.subcategory.slice(0, 15) + ".."
              : rowData.subcategory
            : " ــــ "}
        </Typography>
      ),
    },
    {
      title: (
        <Typography variant="a" align="left">
          Total Count
        </Typography>
      ),
      field: "in_count",
      searchable: false,
      cellStyle: { textAlign: "left" },
      headerStyle: { textAlign: "left" },
    },
    {
      title: (
        <Typography variant="a" align="left">
          Area
        </Typography>
      ),
      field: "area",
      render: (rowData: shopData) => (
        <Typography variant="p" align="left" variantColor={!!rowData.area ? 1 : 2}>
          {!!rowData.area ? rowData.area + " m²" : " ــــ "}
        </Typography>
      ),
      searchable: false,
      cellStyle: { textAlign: "left" },
      headerStyle: { textAlign: "left" },
    },
    {
      title: (
        <Typography
          variant="a"
          align="left"
          tooltip="Percentage of visitors that entered the tenant after dwelling"
          tooltipPlacement="top"
          tooltipIconSize={16}
        >
          Dwelling Convergence Rate
        </Typography>
      ),
      field: "dwelling_convergence_rate",
      render: (rowData: shopData) => (
        <Typography
          variant="p"
          align="left"
          variantColor={
            !!rowData.dwelling_convergence_rate && Math.ceil(rowData?.dwelling_convergence_rate) !== 0 ? 1 : 2
          }
        >
          {!!rowData.dwelling_convergence_rate && Math.ceil(rowData?.dwelling_convergence_rate) !== 0
            ? (rowData.dwelling_convergence_rate * 100).toFixed(2).replace(/[.,]00$/, "") + " %"
            : " ــــ "}
        </Typography>
      ),
      searchable: false,
      cellStyle: { textAlign: "left" },
      headerStyle: { textAlign: "left" },
    },
    {
      title: (
        <Typography
          variant="a"
          align="left"
          tooltip="Count in / (category count /(area / category area))"
          tooltipPlacement="top"
          tooltipIconSize={16}
        >
          Fair Share
        </Typography>
      ),
      field: "categoryFairShare",

      searchable: false,
      render: (rowData: shopData) => (
        <Typography variant="p" variantColor={!!rowData.categoryFairShare ? 1 : 2} align="left">
          {!!rowData.categoryFairShare ? +rowData.categoryFairShare : " ــــ "}
        </Typography>
      ),
      cellStyle: { textAlign: "left" },
      headerStyle: { textAlign: "left" },
    },
    {
      title: (
        <Typography variant="a" align="left" tooltip="Count in / area" tooltipPlacement="top" tooltipIconSize={16}>
          Occupancy Rate
        </Typography>
      ),
      field: "occupancy_rate",
      render: (rowData: shopData) => (
        <Typography
          variant="p"
          variantColor={+rowData.occupancy_rate <= 0 || isNaN(+rowData.occupancy_rate) ? 2 : 1}
          align="left"
        >
          {+rowData.occupancy_rate <= 0 || isNaN(+rowData.occupancy_rate)
            ? " ــــ "
            : typeof rowData.occupancy_rate === "string"
            ? rowData.occupancy_rate
            : rowData.occupancy_rate + " Person / m²"}
        </Typography>
      ),
      searchable: false,
      cellStyle: { textAlign: "left" },
      headerStyle: { textAlign: "left" },
    },
    {
      title: (
        <Typography
          variant="a"
          align="left"
          tooltip="Average dwelling time in minutes"
          tooltipPlacement="top"
          tooltipIconSize={16}
        >
          Avg. Dwelling
        </Typography>
      ),
      field: "avg_dwelling_duration",
      render: (rowData: shopData) => (
        <Typography variant="p" variantColor={!!rowData.avg_dwelling_duration ? 1 : 2} align="left">
          {!!rowData.avg_dwelling_duration
            ? typeof rowData.avg_dwelling_duration === "string"
              ? rowData.avg_dwelling_duration
              : `${(rowData.avg_dwelling_duration / 60).toFixed(2)} Minutes`
            : " ــــ "}
        </Typography>
      ),
      searchable: false,
      cellStyle: { textAlign: "left" },
      headerStyle: { textAlign: "left" },
    },
    // Add other columns in a similar way
  ]

  return (
    <div className={styles.wrapper}>
      <div>
        <Typography
          variant="h2-regular"
          tooltip="Information regarding your shops"
          tooltipPlacement="right"
          tooltipIconSize={22}
          gutterBottom
          variantColor={2}
        >
          Tenants List
        </Typography>
      </div>
      <div className={styles.headerUtils}>
        <div className={styles.searchAndDate}>
          <Search
            handleSearch={handleSearch}
            searchValue={query.searchVal}
            placeholder="E.g. Shop Name"
            loading={isLoading}
          />
          <div className={styles.datePicker}>
            <DateRangePicker
              startDate={startDate}
              endDate={endDate}
              disabled={isLoading}
              onStartDateChange={setStartDate}
              onEndDateChange={setEndDate}
            />
          </div>
          <Button
            endIcon={quickFilterOpen ? <ExpandLessIcon fontSize="small" /> : <ExpandMoreIcon fontSize="small" />}
            className={styles.filterTrigger}
            variant="secondary"
            onClick={() => setQuickFilterOpen((prevValue) => !prevValue)}
            disabled={!categories || (categories && categories?.length < 1)}
          >
            Quick Filters
          </Button>
        </div>
        <Button
          startIcon={<GetAppIcon fontSize="small" className={styles.exportIcon} />}
          variant="secondary"
          onClick={() => handleExportCSV()}
          disabled={isLoading}
        >
          Export
        </Button>
      </div>
      {/* quick filters */}
      <Grid container spacing={1} className={styles.animation} style={{ display: quickFilterOpen ? "" : "none" }}>
        {categories?.map((category: any, index: number) => (
          <Grid item key={index}>
            <Chip
              removable={(query.selectedCategories as string[]).includes(category.name)}
              clickable
              onClick={() => handleFilterByCategory(category.name)}
              onRemove={() => null}
              isSelected={(query.selectedCategories as string[]).includes(category.name)}
              disabled={isLoading}
              className={styles.quickFilterBtn}
            >
              {category.name}
            </Chip>
          </Grid>
        ))}
      </Grid>

      <div className={styles.staffSwitch}>
        <Switch checked={!!query.shouldIncludeStaff} onChange={handleSwitchStaffInclusion} label="Include staff" />
      </div>
      <EntitiesTable
        title="Tenants Table"
        data={filteredTableData}
        isLoading={isLoading}
        height={sectionHeight}
        columns={tableColumns}
        hasPagination
        pageSize={15}
        hasExport={false}
      />
    </div>
  )
}
export default TenantsList
