import { useContext, Fragment } from "react"

import { CardMedia, CardActionArea, Box } from "@mui/material"

import { Typography } from "@synapse-analytics/synapse-ui"
import moment from "moment"

import { definitions } from "../../../../types/Generated/apiTypes"
import { getAuthBaseUrl } from "../../../../utils/auth"
import { FloorsContext } from "../../floorsContext/FloorsContext"

import styles from "./FloorVersionCard.module.scss"

type FloorPlanVersion = definitions["FloorPlanVersionList"]

const APIURL = getAuthBaseUrl()

const FloorVersionCard = ({ version }: { version: FloorPlanVersion }) => {
  const { floorVersionId, selectFloorVersion, formik } = useContext(FloorsContext)

  const handleSelectVersion = () => {
    selectFloorVersion(version.id ? version.id : 0)
    formik.setFieldValue("floorPreview", `${APIURL}${version?.image!}`)
  }

  const isSetupIncomplete = version?.placed_cameras! > version?.mapped_cameras!
  const isSetupComplete =
    version?.mapped_cameras! !== 0 &&
    version?.placed_cameras! !== 0 &&
    version?.placed_cameras! === version?.mapped_cameras!

  return (
    <Fragment>
      <Box
        sx={{
          display: {
            sm: "block",
            xs: "none",
          },
        }}
      >
        <span className={styles.versionDates}>
          <span className={styles.versionDatesTimeline}>{moment(version?.created_at).format("MMMM Do  h:mm A")}</span>
        </span>
      </Box>
      {/* Version Card */}
      <div
        className={styles.card}
        style={{
          borderColor: floorVersionId === version.id ? "var(--selected-border-primary-2)" : "var(--base-border-1)",
        }}
        onClick={handleSelectVersion}
      >
        <CardActionArea>
          {/* floor image */}
          <CardMedia className={styles.floorImage} image={`${APIURL}${version?.image!}`} />
        </CardActionArea>
        <Fragment>
          <div className={styles.versionDetails}>
            <Typography variant="a" align="center">
              Version {version?.version_no}
            </Typography>
          </div>
          <div className={styles.footer}>
            <Typography variant="p" variantColor={2} className={styles.versionCameras} noWrap>
              {version?.placed_cameras!} Cameras
            </Typography>
            <div
              className={styles.versionStatus}
              style={{
                border: `1px solid ${
                  isSetupIncomplete
                    ? "var(--red-border-1)"
                    : isSetupComplete
                    ? "var(--green-border-1)"
                    : "var(--base-border-1)"
                }`,
                color: isSetupIncomplete
                  ? "var(--red-text-1)"
                  : isSetupComplete
                  ? "var(--green-text-1)"
                  : "var(--base-text-1)",
              }}
            >
              <Typography
                variant="label-bold"
                align="center"
                variantColor={2}
                color={isSetupIncomplete ? "negative" : isSetupComplete ? "positive" : "base"}
              >
                {isSetupIncomplete ? "Mapping Incomplete" : isSetupComplete ? "Mapping Complete" : "Mapping Pending"}
              </Typography>
            </div>
          </div>
        </Fragment>
      </div>
    </Fragment>
  )
}

export default FloorVersionCard
