import { NotificationUtils } from "@synapse-analytics/synapse-ui"
import { AxiosError } from "axios"
import domtoimage from "dom-to-image"
import FileSaver from "file-saver"
import JSZip from "jszip"
import moment, { Moment } from "moment"

import { DateTimeFormatOptions, ExportingRefs, FormattedDurationLog } from "../types/Custom/Interfaces"
import { ColoredLineGraphData, ServiceEventType, TableColumn } from "../types/Custom/Types"
import { definitions } from "../types/Generated/apiTypes"

type NodeDownTimeLog = definitions["DailyDownTimeLog"]
type CameraDownTimeLog = definitions["CameraDownTimeLog"]

export const adjustDate = (date: moment.Moment, time: "start" | "end"): moment.Moment => {
  /**
   * utcOffset(0) to standardize on UTC, and local() on the date before passing it to the date picker.
   * This will convert it back to the user's local time zone for display.
   */
  const adjustedDate = date.utcOffset(0).local()
  if (time === "start") {
    adjustedDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
  } else {
    adjustedDate.set({ hour: 23, minute: 59, second: 59, millisecond: 999 })
  }
  return adjustedDate
}

/**
 * Function to extract the page number from pagination backend link
 * @param {string} link - The pagination link
 * @returns {number | undefined} - The extracted page number or undefined if not found
 */
export const extractPageFromBackEndPaginationLink = (link: string): number | undefined => {
  // Check if the link contains '?'
  const hasQueryParam = link.includes("?")

  if (!hasQueryParam) {
    // '?' character not found, return undefined
    return undefined
  }

  // Extract the query parameters from the URL
  const queryString = link.split("?")[1]
  const params = new URLSearchParams(queryString)

  // Get the value of the 'page' parameter
  const pageParam = params.get("page")

  // Parse and return the page number
  return pageParam ? parseInt(pageParam) : undefined // Return undefined if 'page' parameter is not found
}

/**
 * Export graphs as images and save them in a zip file.
 * @param {Moment} startDate - The start date for the analytics period.
 * @param {Moment} endDate - The end date for the analytics period.
 * @param {string} title - The title to use for the zip file.
 * @returns {void}
 */
export const exportGraphs = (
  cardsRefs: ExportingRefs,
  startDate: Moment | null,
  endDate: Moment | null,
  title: string
) => {
  // Check if startDate, endDate, and isLoading are truthy
  // Get an array of all refs
  const arrayOfRefs = Object.values(cardsRefs)

  // Initialize variables for creating the zip file and storing images
  let zip = new JSZip()
  let images: any[] = []
  let tmp = 0

  // Iterate over each ref
  arrayOfRefs.forEach((item) => {
    // Check if the item and item.current are objects with appendChild property
    if (item && item.current && typeof item.current === "object" && "appendChild" in item.current) {
      // Cast item.current to HTMLElement
      const node = item.current as HTMLElement

      // Check if the first child of the node and its first child are instances of HTMLElement
      if (
        node.children[0] &&
        node.children[0] instanceof HTMLElement &&
        node.children[0].childNodes[0] instanceof HTMLElement
      ) {
        // Cast the first child's first child to HTMLElement
        const textNode = node.children[0].childNodes[0] as HTMLElement

        // Get the inner text of the textNode
        const text = textNode.innerText

        // Convert the node to an image blob using domtoimage
        domtoimage
          .toBlob(node)
          .then((blob: any) => {
            // Push the blob to the images array
            images.push(blob)
          })
          .then(() => {
            // Add the image blob to the zip file with a filename based on the text
            zip.file(`${text}.jpeg`, images[tmp], { binary: true })
            tmp++
            // If all images are processed, generate the zip file and save it
            if (tmp === arrayOfRefs.length) {
              zip.generateAsync({ type: "blob" }).then((blob) => {
                FileSaver.saveAs(
                  blob,
                  `${title} Analytics - From  ${startDate?.format("DD-MM-YYYY hh-mm A")} To ${endDate?.format(
                    "MM-DD-YYYY hh-mm A"
                  )}.zip`
                )
              })
            }
          })
      }
    }
  })
}

/**
 * Parses error data received from Axios response into an array of error messages.
 * @param data Error data object received from Axios response.
 * @returns Array of error messages formatted as `${key}: ${value}`.
 */
export const parseErrorMessages = (data: any): string[] => {
  return Object.entries(data).map(([key, value]: [string, any]) => `${key}: ${value}`)
}

/**
 * Handles Axios errors, displaying appropriate error notifications.
 * @param error AxiosError object containing error details.
 * @param operationType Specifies whether the error is from a 'query' or 'mutation'.
 */
export const handleAxiosError = (error: AxiosError, operationType: "query" | "mutation") => {
  const defaultMessage = operationType === "mutation" ? "An error occurred. Please try again." : "An error occurred."

  if (error.response?.data) {
    const errorMessages = parseErrorMessages(error.response.data)
    NotificationUtils.toast(errorMessages.join("\n"), {
      severity: "error",
    })
  } else {
    NotificationUtils.toast(defaultMessage, {
      severity: "error",
    })
  }
}

/**
 * Extracts uppercase initials from a full name. If a last name is provided,
 * it uses the first character of the first name and last name. If no last
 * name, it takes the first two characters of the first name (if a space exists)
 * or the first two characters of the first name.
 *
 * @param {string} name - The full name.
 * @returns {string} Uppercase initials.
 */
export const getNameInitials = (name?: string): string => {
  if (!name) return ""
  const trimmedName = name.trim()
  if (trimmedName.includes(" ")) {
    return trimmedName
      .split(" ")
      .map((word) => word[0])
      .join("")
      .toUpperCase()
  } else {
    return name.slice(0, 2).toUpperCase()
  }
}

interface Log {
  [key: string]: any // Allow any additional properties
  color?: string // The color property is optional
}

export function sortAndColorizeLogs(data: Log[], countKey: keyof Log): Log[] {
  if (data && data.length > 0) {
    // Sort the array by the 'count' property in descending order
    data.sort((a, b) => (b[countKey] as number) - (a[countKey] as number))

    // Set the color property based on the index in the sorted array
    data.forEach((log, index) => {
      log.color = index === 0 ? "var(--primary-background-default)" : "var(--blue-background-2)"
    })
  }

  return data
}

/**
 * Converts raw data into a format suitable for a colored line graph.
 *
 * @param {Array<Object>} data - The raw data to be converted.
 * @param {string} logsKey - The key in the data object that contains the log entries.
 * @param {string} indexByKey - The key in the data object that contains the log entries are indexed by.
 * @param {string} logTimeStampKey - The key in the log entries that contains the timestamp.
 * @param {string} logCountKey - The key in the log entries that contains the count value.
 * @returns {Array<{ id: string, color: string, data: Array<{ x: Date, y: number }> }>}
 * An array of objects formatted for a colored line graph.
 */
export function convertDataToColoredLineGraphAndTable({
  data,
  logsKey,
  indexByKey,
  logTimeStampKey,
  logCountKey,
  timestampFormatting = "h A", // Default timestamp formatting
}: {
  data?: Array<{ [key: string]: any }>
  logsKey: string
  indexByKey: string
  logTimeStampKey: string
  logCountKey: string
  timestampFormatting?: string
}): {
  graphData: Array<{ id: string; color: string; data: Array<{ x: Date; y: number }> }>
  tableData: Record<string, any>[]
} {
  const graphData: Array<{ id: string; color: string; data: Array<{ x: Date; y: number }> }> = []
  const tableData: Record<string, any>[] = []

  if (!data || data.length < 1) return { graphData, tableData }

  data.forEach((log) => {
    if (log[logsKey]) {
      const randomNum = Math.random() * (1 - 0.1) + 0.1
      const randomColor = "#" + Math.floor((randomNum !== 0 ? randomNum : 1) * 16777215).toString(16)
      const logPointsArray: Array<{ x: Date; y: number }> = []

      log[logsKey].forEach((point: { [key: string]: any }) => {
        if (point[logCountKey] > 0) {
          const logTimeStamp = new Date(point[logTimeStampKey])
          typeof point[logTimeStampKey] === "number" && logTimeStamp.setHours(point[logTimeStampKey], 0, 0, 0)
          logPointsArray.push({ x: logTimeStamp, y: point[logCountKey] })
        }

        const readableIndex = moment(point[logTimeStampKey])
          .hour(Number(point[logTimeStampKey]))
          .format(timestampFormatting)
        const existingEntry = tableData.find((entry) => entry[logTimeStampKey] === readableIndex)

        if (existingEntry) {
          existingEntry[log[indexByKey]] = point[logCountKey]
        } else {
          tableData.push({
            [logTimeStampKey]: readableIndex,
            [log[indexByKey]]: point[logCountKey] || 0,
          })
        }
      })

      // Ensure that data logs are sorted in ascending hour order
      logPointsArray.sort((a, b) => a.x.getTime() - b.x.getTime())

      const formattedObj = {
        id: log[indexByKey],
        color: randomColor,
        data: logPointsArray,
      }

      graphData.push(formattedObj)
    }
  })

  // Sort tableData by the logTimeStampKey
  tableData.sort((a, b) => {
    const dateA = moment(a[logTimeStampKey], timestampFormatting).toDate()
    const dateB = moment(b[logTimeStampKey], timestampFormatting).toDate()
    return dateA.getTime() - dateB.getTime()
  })

  return { graphData, tableData }
}

/**
 * Generates a default checkbox state object from the provided graph data.
 *
 * This function takes an array of ColoredLineGraphData and returns an object where each key
 * is the ID of a graph data item and its value is a boolean (defaulting to false).
 *
 * @param {ColoredLineGraphData[]} data - The array of graph data from which to generate default checkbox states.
 * @returns {{ [key: string]: Boolean }} - An object where keys are graph data item IDs and values are false.
 */
export const getDefaultCheckboxes = (
  data: ColoredLineGraphData[]
): {
  [key: string]: Boolean
} => {
  if (!data || data?.length < 1) return { "": false }

  let statesObj: { [key: string]: Boolean } = {}
  // Loop through each item in graphData and set its default state to false
  for (let log of data) {
    statesObj[log.id] = false
  }
  return statesObj
}

/**
 * Calculates the appropriate date slice for a given date range.
 *
 * @param {Moment | null} startDate - The start date of the range. If `null`, the calculation will not proceed.
 * @param {Moment | null} endDate - The end date of the range. If `null`, the calculation will not proceed.
 * @returns {"hour" | "day" | undefined} - Returns "hour" if the difference between `endDate` and `startDate` is less than 1 day,
 *                                  otherwise returns "day". Returns `undefined` if either `startDate` or `endDate` is `null`.
 */
export const calculateDateSlice = (startDate: Moment | null, endDate: Moment | null): "hour" | "day" | undefined => {
  if (!!startDate && !!endDate) {
    const diff = endDate.diff(startDate, "days")
    return diff < 1 ? "hour" : "day"
  }
  return undefined
}

/**
 * Formats logs fetched from the backend to be in the form of actual date and duration in seconds.
 * Also sorts the logs by date and highlights the first day with a special color.
 *
 * @param {NodeDownTimeLog[]|CameraDownTimeLog[]} data - Object containing total down time and array of daily logs.
 * @param {string} dateFormat - Format of the date string, default is "DD MMM".
 * @returns {FormattedDurationLog[]} - Array of formatted logs with date, duration, and color.
 */
export const formatDurationGraphData = (
  data: NodeDownTimeLog[] | CameraDownTimeLog[],
  dateFormat: string = "DD MMM"
): FormattedDurationLog[] => {
  const formattedLogs: FormattedDurationLog[] = []

  if (data) {
    // Format each log
    data.forEach((log) => {
      const formattedDate = moment(log.date).format(dateFormat)

      // Type narrowing to differentiate between NodeDownTimeLog and CameraDownTimeLog
      const formattedLog: FormattedDurationLog = {
        date: formattedDate,
        duration:
          "day_total_down_time" in log
            ? (log as NodeDownTimeLog).day_total_down_time
            : (log as CameraDownTimeLog).duration || 0, // Default to 0 if duration is missing
        color: "var(--blue-background-2)",
      }

      formattedLogs.push(formattedLog)
    })

    // Sort logs by date
    formattedLogs.sort((a, b) => {
      const dateA = moment(a.date, dateFormat)
      const dateB = moment(b.date, dateFormat)
      return dateA.diff(dateB)
    })

    // Assign colors
    if (formattedLogs.length > 0) {
      const maxDuration = Math.max(...formattedLogs.map((log) => log.duration))

      formattedLogs.forEach((log) => {
        log.color = log.duration === maxDuration ? "var(--red-background-1)" : "var(--red-background-2)"
      })
    }
  }

  return formattedLogs
}

/**
 * Formats the given seconds number in a human-readable hours, minutes, and seconds string.
 *
 * @param {number} timeInSeconds - Seconds to convert into hours, minutes, and seconds readable form.
 * @returns {string} - A formatted string representing the time in "X hr Y min Z sec", "Y min", "X hr Z sec", etc.
 *                     It dynamically adds each unit only if it exists. If the time is zero, it returns "0 sec".
 */
export const convertSecondsToReadableTime = (timeInSeconds?: number): string => {
  if (!timeInSeconds || timeInSeconds <= 0) return "0 sec"

  const hours = Math.floor(timeInSeconds / 3600)
  const minutes = Math.floor((timeInSeconds % 3600) / 60)
  const seconds = timeInSeconds % 60

  let timeString = ""

  // Add hours if they exist
  if (hours > 0) {
    timeString += `${hours} hr`
  }

  // Add minutes if they exist, ensuring to add a space if hours already exist
  if (minutes > 0) {
    timeString += timeString ? ` ${minutes} min` : `${minutes} min`
  }

  // Add seconds if they exist, ensuring to add a space if either hours or minutes already exist
  if (seconds > 0) {
    timeString += timeString ? ` ${seconds} sec` : `${seconds} sec`
  }

  // Return the final time string or "0 sec" if none of the units were added
  return timeString || "0 sec"
}

/**
 * Converts a given time to the total minutes from the start of the day.
 *
 * @param {string} time - The time string to convert, formatted as an ISO 8601 string (e.g., '2024-09-24T14:32:19').
 * @returns {number} - The total number of minutes from 12:00 AM (start of the day) to the given time.
 *
 * @example
 * // 14:32 -> 872 minutes
 */
export const convertTimeToMinutes = (time: string): number => moment(time).diff(moment(time).startOf("day"), "minutes")

/**
 * Function to convert data to CSV and trigger download
 * @param {TableColumn[]} columns - The columns of the table
 * @param {any[]} data - The data to be exported
 */
export const exportTableDataToCSV = (columns: TableColumn[], data: any[], title?: string) => {
  const csvRows = []

  // Get headers
  const headers = columns.map((col) => col.title)
  csvRows.push(headers.join(","))

  // Loop over the rows
  for (const row of data) {
    const values = columns.map((col) => {
      const value = row[col.field]
      return typeof value === "string" ? `"${value.replace(/"/g, '""')}"` : value
    })
    csvRows.push(values.join(","))
  }

  // Create a blob and trigger download
  const csvString = csvRows.join("\n")
  const blob = new Blob([csvString], { type: "text/csv" })
  const url = window.URL.createObjectURL(blob)
  const a = document.createElement("a")
  a.setAttribute("hidden", "")
  a.setAttribute("href", url)
  a.setAttribute("download", title ? `${title}.csv` : "table_data.csv")
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
}

/**
 * Exports fetched data to a CSV file and triggers a download.
 * This function is used to export data from the backend to a CSV file.
 *
 * @param {string} data - The data to be exported, formatted as a CSV string.
 * @param {string} filename - The name of the file to be downloaded (without the .csv extension).
 */
export const exportFetchedDataToCSV = (data: string, filename: string) => {
  const blob = new Blob([data], { type: "text/csv;charset=utf-8;" })
  const link = document.createElement("a")
  const url = URL.createObjectURL(blob)

  link.setAttribute("href", url)
  link.setAttribute("download", `${filename}.csv`)
  link.style.visibility = "hidden"

  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

/**
 * Formats a given date or timestamp string for display in a table based on the specified time grain.
 *
 * @param {Date | string} [date] - The date or timestamp string to format. If not provided, returns undefined.
 * @param {string} [timeGrain] - The time grain to format the date for. Can be "hour" or any other value. Defaults to a more detailed format if not "hour".
 * @returns {string} - The formatted date string.
 */
export const formatTimestampForTable = (date?: Date | string, timeGrain?: string) => {
  if (!date) return
  const options: DateTimeFormatOptions =
    timeGrain === "hour"
      ? { hour12: true, hour: "2-digit" as const }
      : { hour12: true, weekday: "short", day: "numeric", month: "short" }

  return new Date(date).toLocaleString("en-US", options)
}
/**
 * Function to get the initial active stats tab based on the screen size and the presence of graph and table props.
 * @param {Object} options - The options for the function.
 * @param {any} options.graphProps - The graph props.
 * @param {any} options.tableProps - The table props.
 * @param {boolean} options.xSmallScreen - Whether the screen is extra small.
 * @returns {number} The initial active stats tab.
 */
export const getInitialActiveStatsTab = ({
  graphProps,
  tableProps,
  xSmallScreen,
}: {
  graphProps?: any
  tableProps?: any
  xSmallScreen?: boolean
}): number => {
  if (graphProps && tableProps) {
    return xSmallScreen ? 1 : 0
  } else if (graphProps) {
    return 0
  } else if (tableProps) {
    return 1
  }
  return 0
}

export const ServiceEventTypeMapper: Record<number, ServiceEventType> = {
  0: "fire",
  1: "violence",
  2: "motion",
  3: "intrusion",
  4: "blackListCar",
  5: "blackListPerson",
  6: "stray_animals",
}
