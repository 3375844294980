import React, { FC, useState, useEffect, useRef } from "react"

import NearMeIcon from "@mui/icons-material/NearMe"
import PauseIcon from "@mui/icons-material/Pause"
import PlayArrowIcon from "@mui/icons-material/PlayArrow"
import SkipNextIcon from "@mui/icons-material/SkipNext"
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious"
import { Slider, Typography } from "@mui/material"

import { Chip } from "@synapse-analytics/synapse-ui"
import moment from "moment"

import { Mark } from "../../../types/Custom/Interfaces"
import { definitions } from "../../../types/Generated/apiTypes"
import { getAuthBaseUrl } from "../../../utils/auth"
import { updateSliderMarks, calculateStartValue } from "../../../utils/mcpUtils"
import MCPPath from "./MCPPath"

import styles from "./MCPTimeline.module.scss"

type MCPHourly = definitions["FloorVersionMCPLogs"] | definitions["CameraMCPLogs"]
type MCPLogs = definitions["LinePoint"]
type FloorPlan = definitions["FloorPlanVersionList"]
type Camera = definitions["CameraRetrieveUpdate"]
interface allData {
  time_stamp: string
  version?: number
  data: MCPLogs[][]
}

interface Props {
  marks: Mark[]
  dateDiff: number
  mcpLogs: MCPHourly[] | undefined
  floorPlanVersions?: FloorPlan[]
  versionsCount?: number[]
  selectedCamera?: Camera
  isCameraMCP?: boolean
}
const APIURL = getAuthBaseUrl()

const MCPTimeline: FC<Props> = ({
  mcpLogs,
  dateDiff,
  marks,
  floorPlanVersions,
  versionsCount,
  isCameraMCP,
  selectedCamera,
}) => {
  const imageRef = useRef<HTMLImageElement>(null)
  const [play, setPlay] = useState(false)
  const [clickPoint, setClickPoint] = useState(false)
  const [sliderValue, setSliderValue] = useState(0)
  const [allData, setAllData] = useState<allData[]>()
  const [allMarks, setAllMarks] = useState<Mark[]>()
  const [dataToDraw, setDataToDraw] = useState<MCPLogs[][]>([])
  const [maxValue, setMaxValue] = useState(0)
  const [startValues, setStartValues] = useState<number[]>([])
  const [imageSize, setImageSize] = useState({ width: 736, height: 416 })
  const [activeVersion, setActiveVersion] = useState(1)

  // set allMarks state when marks change
  useEffect(() => {
    if (marks && marks?.length > 0) setAllMarks(marks)
  }, [marks])

  const handleSliderChange = (event: any, value: any) => {
    if (allMarks && allMarks?.length > 1) {
      setSliderValue(allMarks!.findIndex((mark) => mark.value === value))
      setClickPoint(true)
    }
  }

  const handleClick = () => {
    if (mcpLogs && mcpLogs.length > 0 && allMarks && allMarks?.length > 1) {
      setPlay(!play)
      setClickPoint(false)
      if (sliderValue === 24 || sliderValue >= 30) {
        setSliderValue(0)
      }
    }
  }
  // reset dataToDraw state and slider value on mount
  useEffect(() => {
    setSliderValue(0)
    setDataToDraw([])
  }, [])

  // sort logs , set slider marks and max value
  useEffect(() => {
    // Handle mcp data
    if (mcpLogs && mcpLogs.length > 0 && allMarks) {
      // sort the array of logs by the order of hours/days
      const sortedData = mcpLogs.sort((a, b) => moment(a.time_stamp).diff(moment(b.time_stamp)))
      setAllData(sortedData)
      // update slider marks
      updateSliderMarks(allMarks, sortedData, dateDiff)
      // get max value of all logs
      const dataMax = sortedData.reduce((acc, curr) => {
        const maxInLogs = curr.data.reduce((acc2, curr2) => {
          const maxInLog = curr2.reduce((acc3, curr3) => {
            return Math.max(acc3, curr3.val)
          }, -Infinity)

          return Math.max(acc2, maxInLog)
        }, -Infinity)

        return Math.max(acc, maxInLogs)
      }, -Infinity)
      setMaxValue(dataMax)
    }
  }, [allMarks, mcpLogs, dateDiff])
  useEffect(() => {
    const legendValues = calculateStartValue(0, maxValue)
    setStartValues(legendValues)
  }, [maxValue])

  // Play mcp points (pick logs to draw form all data)
  useEffect(() => {
    // Handle mcp data
    if (allData && allData.length > 0 && allMarks) {
      const dataToDraw =
        allData && allData?.length > 0 ? allData[sliderValue]?.data.filter((log) => log.length !== 0) : []
      setDataToDraw(dataToDraw)
      if (!isCameraMCP) {
        const dataVersion = allData && allData?.length > 0 ? allData[sliderValue]?.version : 1
        setActiveVersion(dataVersion!)
      }
    }
  }, [allMarks, allData, isCameraMCP, sliderValue])

  // handle play timeline and play button
  useEffect(() => {
    if (play && allMarks?.length !== 0 && allMarks !== undefined) {
      const timer = setInterval(() => {
        if (sliderValue === allData!.length - 1) {
          setSliderValue(0)
        } else {
          setSliderValue(sliderValue + 1)
        }
      }, 1200)
      return () => clearTimeout(timer)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allMarks, play, sliderValue])
  // set image height and width
  useEffect(() => {
    const img = new Image()
    if (!isCameraMCP && floorPlanVersions && floorPlanVersions[activeVersion - 1]?.image) {
      const floorImage = floorPlanVersions[activeVersion - 1].image
      if (floorImage) {
        img.src = `${APIURL}${floorImage}`
        img.onload = () => {
          setImageSize({
            height: img.height,
            width: img.width,
          })
        }
      }
    }
  }, [floorPlanVersions, activeVersion, isCameraMCP])
  const handleNextMark = () => {
    if (sliderValue === allData!.length - 1) {
      setSliderValue(0)
    } else {
      setSliderValue((prevValue) => prevValue + 1)
    }
  }
  const handlePreviousMark = () => {
    if (sliderValue === 0) {
      setSliderValue(allData!.length - 1)
    } else {
      setSliderValue((prevValue) => prevValue - 1)
    }
  }

  return (
    <div
      className={styles.wrapper}
      style={{
        background: play
          ? "var(--base-background-1)"
          : "linear-gradient(0deg, var(--base-text-1) 0%, rgba(0, 0, 0, 0) 100%) , linear-gradient(180deg, rgba(0, 0, 0, 0.65) 0%, rgba(196, 196, 196, 0) 100%)",
      }}
    >
      <div className={styles.legendsWrapper}>
        <div style={{ display: "column" }}>
          <Typography className={styles.LegendsTitle}>
            Color: <br /> People Count in Area
          </Typography>
          <div className={styles.valuesAndSymbols}>
            <div className={styles.valuesLegendWrapper}>
              <div className={styles.valuesLegend}></div>
              <div className={styles.valuesAnnotation}>
                <Typography className={styles.minMaxValues}>Min</Typography>
                {startValues.slice(1).map((value, index) => (
                  <Typography key={index} className={styles.minMaxValues}>
                    {Math.round(value).toLocaleString()}
                  </Typography>
                ))}
                <Typography className={styles.minMaxValues}>{maxValue.toLocaleString()}</Typography>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.symbolLegendWrapper}>
          <Typography className={styles.LegendsTitle} style={{ marginBottom: 10 }}>
            Shape: <br /> Line Formation
          </Typography>
          <div className={styles.symbolsContainer}>
            <div className={styles.symbolContainer}>
              <div className={styles.startSymbol}></div>
              <Typography className={styles.symbolDescription}>Start Of Path</Typography>
            </div>
            <div className={styles.symbolContainer}>
              <NearMeIcon style={{ color: "var(--blue-text-2)" }} />
              <Typography className={styles.symbolDescription}>End Of Path</Typography>
            </div>
          </div>
        </div>
      </div>
      <div id="heatmap" className={styles.mcpWrapper}>
        <div
          style={{
            height: imageSize.height,
            width: imageSize.width,
          }}
        >
          <img
            ref={imageRef}
            className={styles.floorPlan}
            style={{
              opacity: play || clickPoint ? 0.9 : 0.6,
            }}
            src={
              isCameraMCP && selectedCamera?.sample_frame
                ? selectedCamera?.sample_frame
                : floorPlanVersions && `${APIURL}${floorPlanVersions[activeVersion - 1]?.image!}`
            }
            alt="floorPlan"
            width={imageSize.width}
            height={imageSize.height}
          />
          {!play && allMarks && (
            <Typography className={styles.floorTitle}>
              {isCameraMCP && selectedCamera?.name
                ? selectedCamera?.name
                : floorPlanVersions && floorPlanVersions[activeVersion - 1]?.floor_name}{" "}
              MCP - {allMarks[sliderValue].label}
            </Typography>
          )}
          {dataToDraw && allMarks && dataToDraw.length > 0 && (
            <div
              style={{ position: "absolute" }}
              key={`${dataToDraw[0][0].x * dataToDraw[0][0].y}_${allMarks[sliderValue].label}`}
            >
              {dataToDraw.map((lineData, index) => (
                <MCPPath
                  lineData={lineData}
                  maxValue={maxValue}
                  key={index}
                  width={imageSize.width}
                  height={imageSize.height}
                />
              ))}
            </div>
          )}
        </div>
      </div>

      <div className={styles.sliderWrapper} style={{ overflow: "hidden" }}>
        {/* floor version number */}
        {!isCameraMCP && versionsCount && versionsCount.length > 0 && (
          <div className={styles.versionWrapper}>
            {versionsCount.map((version, index) => (
              <Chip
                size="small"
                key={index}
                isSelected={activeVersion === version}
                clickable={false}
                className={styles.versionTitle}
              >
                Version {version}
              </Chip>
            ))}
          </div>
        )}
        <div className={styles.playerWrapper}>
          <Slider
            sx={{
              "& .MuiSlider-markLabel": {
                background: play ? "rgba(255, 255, 255, 0.4)" : "var(--base-background-3)",
              },
              "& .MuiSlider-mark": {
                background: play ? (isCameraMCP ? "#ABABAB" : "#929292") : "#0D0D0D",
              },
              "& .MuiSlider-markActive": {
                background: play ? (isCameraMCP ? "#ABABAB" : "#929292") : "#0D0D0D",
              },
            }}
            classes={{
              root: styles.root,
              thumb: styles.thumb,
              mark: styles.mark,
              rail: styles.rail,
              track: styles.track,
              markLabel: styles.markLabel,
              markLabelActive: styles.markLabelActive,
              markActive: styles.markActive,
            }}
            disabled={mcpLogs && mcpLogs.length > 0 ? false : true}
            onChange={handleSliderChange}
            defaultValue={0}
            value={sliderValue}
            aria-labelledby="discrete-slider"
            step={null}
            max={allData && allData.length > 1 ? allData.length - 1 : 1}
            marks={allMarks && allMarks}
          />

          <div className={styles.playerControls}>
            <SkipPreviousIcon
              sx={{
                background:
                  allMarks && allMarks.length > 1 ? "var(--base-background-3)" : "var(--secondary-background-default)",
              }}
              className={styles.playerControl}
              onClick={handlePreviousMark}
            />
            {play ? (
              <PauseIcon
                sx={{
                  background:
                    allMarks && allMarks.length > 1
                      ? "var(--base-background-3)"
                      : "var(--secondary-background-default)",
                }}
                className={styles.playIconSlider}
                onClick={handleClick}
              />
            ) : (
              <PlayArrowIcon
                sx={{
                  background:
                    allMarks && allMarks.length > 1
                      ? "var(--base-background-3)"
                      : "var(--secondary-background-default)",
                }}
                className={styles.playIconSlider}
                onClick={handleClick}
              />
            )}
            <SkipNextIcon
              sx={{
                background:
                  allMarks && allMarks.length > 1 ? "var(--base-background-3)" : "var(--secondary-background-default)",
              }}
              className={styles.playerControl}
              onClick={handleNextMark}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default MCPTimeline
