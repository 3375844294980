import { FC, useState, useEffect, useMemo } from "react"

import FaceIcon from "@mui/icons-material/Face"
import StackedBarChartIcon from "@mui/icons-material/StackedBarChart"
import TableViewIcon from "@mui/icons-material/TableView"
import WcIcon from "@mui/icons-material/Wc"
import { Box, Card, useMediaQuery } from "@mui/material"
import { useTheme } from "@mui/material/styles"

import { Typography } from "@synapse-analytics/synapse-ui"

import { TableColumn } from "../../types/Custom/Types"
import { getInitialActiveStatsTab } from "../../utils/genericHelpers"
import ButtonSwitch from "../Buttons/ButtonSwitch"
import GraphEmptyState from "../graphs/GraphEmptyState"
import PaginatedBarGraph from "../graphs/PaginatedBarGraph"
import CountTable from "../tables/GenericTable"

import styles from "./PaginatedBarGraphCard.module.scss"

interface GraphProps {
  /**
   * The field to index the data by.
   */
  indexBy?: string
  /**
   * The keys to use for the graph data.
   */
  keys?: string[]
  /**
   * A custom title for the bar graph.
   */
  customBarTitle?: string
  /**
   * Indicates if the graph is displaying duration data.
   */
  isDurationGraph?: boolean
  /**
   * Indicates if the distribution of data should be displayed.
   */
  shouldDisplayDistribution?: boolean
  /**
   * Indicates if the graph is paginated.
   */
  isPaginated?: boolean
  /**
   * The Maximum number of bars to show in one page.
   */
  maxBars?: number
  /**
   * The keys to use for gender data.
   */
  genderKeys?: string[]
  /**
   * The keys to use for age data.
   */
  ageKeys?: string[]
}

interface TableProps {
  columns: TableColumn[]
}

interface Props {
  /**
   * The data to be displayed in the graph or table.
   */
  data?: any[]
  /**
   * The title of the card.
   */
  title?: string
  /**
   * The subtitle of the card.
   */
  subtitle?: string
  /**
   * The properties for the graph.
   */
  graphProps?: GraphProps
  /**
   * The properties for the table.
   */
  tableProps?: TableProps
  /**
   * Indicates if the component is currently loading data.
   */
  isLoading: boolean
  /**
   * A reference to an external element or component.
   */
  reference?: any
  /**
   * The start date for the data range.
   */
  startDate?: string
  /**
   * The end date for the data range.
   */
  endDate?: string
  /**
   * Indicates if no category has been selected for the data.
   */
  noCategorySelected?: boolean
  /**
   * The height of the content area.
   */
  contentHeight?: number

  /**
   * Indicates if missing data should be included in the display.
   */
  shouldIncludeMissingData?: boolean
}

/**
 * PaginatedBarGraphCard component
 *
 * This component renders a card that contains a paginated bar graph and a table view.
 * It allows switching between the graph and table views, and supports pagination for the graph.
 * The component also handles different states such as loading, missing data, and no category selected.
 *
 * @param {Props} props - The props for the component
 * @returns {JSX.Element} The rendered component
 */
const PaginatedBarGraphCard: FC<Props> = ({
  data,
  graphProps,
  tableProps,
  title,
  subtitle,
  shouldIncludeMissingData,
  isLoading,
  reference,
  endDate,
  startDate,
  noCategorySelected,
  contentHeight = 340,
}) => {
  const maxBars = graphProps?.maxBars ?? 10
  const isPaginated = graphProps?.isPaginated ?? true
  /**
   * Checks if the data contains gender information.
   * @returns {boolean} True if the data contains gender information, false otherwise.
   */
  const hasGenderData = data?.some((item) =>
    Object.keys(item).some((key) => key.includes("male") || key.includes("female"))
  )

  /**
   * Checks if the data contains age information.
   * @returns {boolean} True if the data contains age information, false otherwise.
   */
  const hasAgeData = data?.some((item) =>
    Object.keys(item).some((key) => key.includes("adult") || key.includes("child"))
  )

  const theme = useTheme()
  const xSmallScreen = useMediaQuery(theme.breakpoints.down("sm"))

  const [currentPage, setCurrentPage] = useState(1)
  /**
   * Initializes the active tab state based on the presence of gender or age data.
   * If gender data is present, sets the active tab to 0. If age data is present but gender data is not, sets the active tab to 1.
   * Otherwise, sets the active tab to 0.
   */
  const [activeTab, setActiveTab] = useState(() => {
    if (hasGenderData) return 0
    if (hasAgeData) return 1
    return 0
  })

  const [activeStatsTab, setActiveStatsTab] = useState(
    getInitialActiveStatsTab({ graphProps, tableProps, xSmallScreen })
  )

  const maxPages = useMemo(() => Math.ceil((data ? data.length : 0) / maxBars), [data, maxBars])

  // resetting page number on changing filters changes
  useEffect(() => {
    if (!!startDate && !!endDate) {
      setCurrentPage(1)
    }
  }, [activeStatsTab, endDate, startDate, shouldIncludeMissingData])

  return (
    <Card className={styles.cardWrapper} ref={reference}>
      <div className={styles.header}>
        <Box
          className={styles.cardTitle}
          sx={{
            width: {
              xl: "auto",
              xs: "min-content",
            },
          }}
        >
          <Typography variant="h2-bold" variantColor={2} noWrap>
            {title || graphProps?.customBarTitle}
            {activeStatsTab === 0 &&
              isPaginated &&
              !isLoading &&
              data &&
              data.length > maxBars &&
              `: Page ${currentPage}/${maxPages}`}
          </Typography>

          {graphProps?.shouldDisplayDistribution && hasGenderData && hasAgeData && activeStatsTab === 0 && (
            <ButtonSwitch
              activePage={activeTab}
              pages={["Gender", "Age"]}
              handleSelectButton={setActiveTab}
              disabled={[noCategorySelected || isLoading].some((flag) => !!flag)}
              pagesIcons={[<WcIcon />, <FaceIcon />]}
            />
          )}
        </Box>

        {graphProps && tableProps && (
          <Box
            sx={{
              display: {
                xs: "none",
                md: "block",
              },
            }}
          >
            <ButtonSwitch
              activePage={activeStatsTab}
              pages={["Graph", "Table"]}
              handleSelectButton={setActiveStatsTab}
              disabled={noCategorySelected || isLoading}
              pagesIcons={[<StackedBarChartIcon />, <TableViewIcon />]}
            />
          </Box>
        )}
      </div>
      {subtitle && (
        <Typography variant="p" variantColor={2}>
          {subtitle}
        </Typography>
      )}
      {shouldIncludeMissingData && (
        <Box display="flex" alignItems="center" mb={1}>
          <span className={styles.colorSquare} />
          <Typography variant="p">Missing data generated by the admin</Typography>
        </Box>
      )}
      {noCategorySelected ? (
        <GraphEmptyState noCategorySelected />
      ) : activeStatsTab === 0 && graphProps ? (
        <PaginatedBarGraph
          barGraphData={data}
          isLoading={isLoading}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          indexBy={graphProps.indexBy}
          keys={graphProps.keys}
          key={`${shouldIncludeMissingData ? "_missingDataIncluded" : ""}`}
          shouldIncludeMissingData={shouldIncludeMissingData}
          maxBars={maxBars}
          ageGenderKeys={
            graphProps.shouldDisplayDistribution
              ? activeTab === 0
                ? graphProps.genderKeys || ["male_count", "female_count", "unknown_count"]
                : graphProps.ageKeys || ["adult_count", "child_count", "unknown_count"]
              : false
          }
          customBarTitle={graphProps.customBarTitle}
          isDurationGraph={graphProps.isDurationGraph}
          height={contentHeight}
          isPaginated={isPaginated}
        />
      ) : activeStatsTab === 1 && tableProps ? (
        <CountTable
          data={data}
          isLoading={isLoading}
          columns={tableProps.columns}
          title={title}
          height={contentHeight - 10}
          verticalMargin={1}
        />
      ) : null}
    </Card>
  )
}
export default PaginatedBarGraphCard
