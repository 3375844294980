import React, { FC, useEffect, Fragment, ChangeEvent, useMemo, useState } from "react"
import { useQuery } from "react-query"

import { Grid } from "@mui/material"

import { Switch, Tooltip, Typography } from "@synapse-analytics/synapse-ui"
import { AxiosError } from "axios"
import moment from "moment"
import { NumberParam, useQueryParam, withDefault } from "use-query-params"
import { shallow } from "zustand/shallow"

import { VisionAPI } from "../../../API/VisionAPI"
import DataOverTimeCard from "../../../components/GraphCards/DataOverTimeCard"
import PaginatedBarGraph from "../../../components/GraphCards/PaginatedBarGraphCard"
import Insights from "../../../components/Insights"
import GraphTooltip from "../../../components/graphs/helpers/GraphTooltip"
import { useBranchesStore } from "../../../store"
import { EntrancesBarData, LineGraphData, TableColumn } from "../../../types/Custom/Types"
import { definitions } from "../../../types/Generated/apiTypes"
import {
  calculateSumPerTime,
  convertDataToHourlyLineGraph,
  convertLogsDataToGateStats,
  formatInsightsData,
  replaceMissingData,
} from "../../../utils/counterUtils"
import { formatTimestampForTable } from "../../../utils/genericHelpers"
import GatesIcon from "../assets/gate.svg?react"
import PeopleIcon from "../assets/peopleGroup.svg?react"
import AvgCountWeek from "./AvgCountWeek"
import HourlyAvgData from "./HourlyAvgData"
import NumericStats from "./NumericStats"
import TotalCountsHeatmap from "./TotalCountsHeatmap"
import WeekDayFilter from "./WeekDayFilter"

import styles from "./CounterGates.module.scss"

type Gate = definitions["Gate"]

type MissingData = definitions["MissingSliceCounterLog"]
type EntitiesLogs = definitions["EntitiesLogs"]
type InsightsData = definitions["CounterInsights"]

type OverTimeTableData = {
  timestamp: Date | string
  "Count In"?: number
  "Count Out"?: number
  "Dwelling counts"?: number
  "Dwelling time"?: number
  isModified?: boolean
  missingInfo?: {
    fileName: string
    entities: string
    countIn: number
    countOut: number
  }[]
}

type refs = {
  lineGraphRef: any
  gatesGraphRef: any
  avgGraphRef: any
  hourlyAvgGraphRef: any
  heatmapGraphRef: any
  visitorsRef: any
  occupancyRef: any
}

interface Props {
  isLive: boolean
  startDate?: string
  endDate?: string
  timeGrain: "day" | "hour"
  interval?: Duration
  shouldIncludeMissingData?: boolean
  shouldIncludeStaff?: boolean
  setLoadingState?: (isLoading: boolean) => void
  handleSwitchMissingData?: (event: ChangeEvent<HTMLInputElement>) => void
  handleSwitchStaffInclusion?: (event: ChangeEvent<HTMLInputElement>) => void
  refs: refs
}

const CounterGates: FC<Props> = ({
  isLive,
  startDate,
  endDate,
  timeGrain,
  interval,
  setLoadingState,
  shouldIncludeMissingData,
  shouldIncludeStaff,
  handleSwitchMissingData,
  handleSwitchStaffInclusion,
  refs,
}) => {
  const [selectedDay, setSelectedDay] = useQueryParam("day", withDefault(NumberParam, null))

  const [selectedBranch] = useBranchesStore(
    (state: { selectedBranch: number | null }) => [state.selectedBranch],
    shallow
  )
  const [dataType, setDataType] = useState<string>("Gates counts")

  const { data: counterLogs, isLoading: gateLogsLoading } = useQuery<EntitiesLogs>(
    [
      "fetchGatesLogs",
      startDate, // 1
      endDate, // 2
      timeGrain, // 3
      selectedBranch, // 4
      shouldIncludeStaff, // 5
      !!selectedDay && selectedDay >= 0 && selectedDay <= 6 // 6
        ? selectedDay + 1
        : null,
    ],
    ({ queryKey }) =>
      VisionAPI.fetchGatesCounterStats({
        from_date: queryKey[1] as string, // start date
        to_date: queryKey[2] as string, // end date
        date_slice: queryKey[3] as string, // time grain
        branch: queryKey[4] as number, // selected branch
        include_staff: queryKey[5] as boolean, // staff filter
        week_day: queryKey[6] as number, // selected week day filter
      }),
    {
      enabled: !!endDate && !!timeGrain && !!selectedBranch,
      refetchInterval: isLive ? 30000 : false,
    }
  )
  const { data: lastWeekLogs, isLoading: lastWeekLoading } = useQuery<EntitiesLogs>(
    [
      "fetchGatesStatsLastWeek",
      moment().subtract(7, "days").format("YYYY-MM-DD"),
      moment().subtract(7, "days").format("YYYY-MM-DD"),
      "hour",
      selectedBranch,
      shouldIncludeStaff,
    ],
    ({ queryKey }) =>
      VisionAPI.fetchGatesCounterStats({
        from_date: queryKey[1] as string,
        to_date: queryKey[2] as string,
        date_slice: queryKey[3] as string,
        branch: queryKey[4] as number,
        include_staff: queryKey[5] as boolean,
      }),
    {
      enabled: !!isLive && !!selectedBranch,
    }
  )
  const { data: avgCounterLogs, isLoading: avgLogsLoading } = useQuery<EntitiesLogs>(
    [
      "fetchAvgGatesLogs",
      startDate, // 1
      endDate, // 2
      "hour", // 3
      selectedBranch, // 4
      shouldIncludeStaff, // 5
      !!selectedDay && selectedDay >= 0 && selectedDay <= 6 // 6
        ? selectedDay + 1
        : null,
    ],
    ({ queryKey }) =>
      VisionAPI.fetchGatesCounterStats({
        from_date: queryKey[1] as string,
        to_date: queryKey[2] as string,
        date_slice: queryKey[3] as string,
        branch: queryKey[4] as number,
        include_staff: queryKey[5] as boolean,
        week_day: queryKey[6] as number,
      }),
    {
      enabled:
        !isLive &&
        !!endDate &&
        !!timeGrain &&
        !!interval &&
        (interval!.days! >= 7 || interval!.months! >= 1) &&
        !!selectedBranch,
    }
  )
  const { data: insightsData, isLoading: insightsDataLoading } = useQuery<InsightsData[]>(
    ["fetchCounterInsights", startDate, endDate, selectedBranch],
    ({ queryKey }) =>
      VisionAPI.fetchCounterInsights({
        from_dt: queryKey[1] as string,
        to_dt: queryKey[2] as string,
        branch: queryKey[3] as number,
      }),
    {
      enabled: !!endDate && !isLive && !!selectedBranch,
    }
  )

  const { data: entranceEntitiesData, isLoading: entranceEntitiesLoading } = useQuery<Gate[], AxiosError>(
    ["fetchEntranceEntities", selectedBranch],
    ({ queryKey }) => VisionAPI.fetchEntrances({ branch: queryKey[1] as number }),
    {
      enabled: !!selectedBranch,
    }
  )

  // Use useMemo to format Gates
  const entranceEntities = useMemo(() => {
    let entranceEntityObj: {
      [key: string]: Gate
    } = {}
    entranceEntitiesData?.forEach((gate: Gate) => (entranceEntityObj[gate.id!] = { ...gate }))
    return entranceEntityObj
  }, [entranceEntitiesData])

  const { data: missingData, isLoading: missingDataLoading } = useQuery<MissingData[]>(
    ["fetchGatesMissingLogs", startDate, endDate, timeGrain, selectedBranch],
    ({ queryKey }) =>
      VisionAPI.fetchMissingCounterLogs({
        from_date: queryKey[1] as string,
        to_date: queryKey[2] as string,
        date_slice: queryKey[3] as string,
        branch: queryKey[4] as number,
        entities_type: "gate",
      }),
    {
      enabled: !!endDate && !!timeGrain && shouldIncludeMissingData && !isLive && !!selectedBranch,
    }
  )

  // a universal loading state that is true if any of logs are loading
  useEffect(() => {
    if (setLoadingState) {
      if ([avgLogsLoading, gateLogsLoading, lastWeekLoading, missingDataLoading].some((element) => element === true)) {
        setLoadingState(true)
      } else {
        setLoadingState(false)
      }
    }
  }, [avgLogsLoading, gateLogsLoading, lastWeekLoading, missingDataLoading, setLoadingState])

  const formattedInsightsData = useMemo(() => {
    if (!insightsDataLoading && insightsData && insightsData.length > 0) {
      const formattedData = formatInsightsData(insightsData, {
        count_increase: <PeopleIcon />,
        count_decrease: <PeopleIcon />,
        gates_distribution_vary: <GatesIcon />,
      })
      return endDate !== null ? formattedData.reverse() : formattedData
    }
    return []
  }, [insightsData, insightsDataLoading, endDate])

  const handleDaySelected = (dayValue: number) => {
    if (selectedDay === dayValue) {
      setSelectedDay(null)
    } else {
      setSelectedDay(dayValue)
    }
  }

  // converting data to suitable format that nivo bar graph accepts
  // using the appropriate formatting function based on the sent data type
  const barGraphData: EntrancesBarData[] | undefined = useMemo(() => {
    if (!gateLogsLoading && counterLogs && endDate) {
      const replacedLogs = shouldIncludeMissingData
        ? replaceMissingData(counterLogs?.entities_logs, missingData, entranceEntities)
        : counterLogs?.entities_logs

      return convertLogsDataToGateStats(
        Object.fromEntries(
          Object.entries(entranceEntities).map(([key, value]) => [key, { id: String(value.id), name: value.name }])
        ),
        replacedLogs
      )
    }
  }, [counterLogs, endDate, entranceEntities, gateLogsLoading, missingData, shouldIncludeMissingData])

  const barGraphTableColumns: TableColumn[] = [
    {
      title: "Gate",
      field: "entity",
      searchable: false,
      render: (rowData: EntrancesBarData) => (
        <div style={{ color: rowData.isModified ? "var(--red-text-2)" : "" }}>{rowData.entity}</div>
      ),
    },
    {
      title: "Count In",
      field: "Count In",
      searchable: false,
      render: (rowData: EntrancesBarData) => (
        <Tooltip
          placement="top"
          variant="secondary"
          title={
            rowData.isModified ? (
              <GraphTooltip
                missingInfo={rowData?.missingInfo}
                isTableTooltip
                originalCountIn={rowData?.["Count In"]}
                originalCountOut={rowData?.["Count Out"]}
                missingCountIn={rowData?.["Missing Count In"]}
                missingCountOut={rowData?.["Missing Count Out"]}
              />
            ) : (
              ""
            )
          }
        >
          <div style={{ color: rowData.isModified ? "var(--red-text-2)" : "" }}>
            {(rowData["Count In"] || 0) + (rowData["Missing Count In"] || 0)}
          </div>
        </Tooltip>
      ),
    },
    ...(shouldIncludeMissingData
      ? [
          {
            title: "Missing Count In",
            cellStyle: {
              display: "none",
            },
            headerStyle: {
              display: "none",
            },
            field: "Missing Count In",
            searchable: false,
          },
        ]
      : []),
    {
      title: "Count Out",
      field: "Count Out",
      searchable: false,
      render: (rowData: EntrancesBarData) => (
        <Tooltip
          variant="secondary"
          placement="top"
          title={
            rowData.isModified ? (
              <GraphTooltip
                missingInfo={rowData?.missingInfo}
                isTableTooltip
                originalCountIn={rowData?.["Count In"]}
                originalCountOut={rowData?.["Count Out"]}
                missingCountIn={rowData?.["Missing Count In"]}
                missingCountOut={rowData?.["Missing Count Out"]}
              />
            ) : (
              ""
            )
          }
        >
          <div style={{ color: rowData.isModified ? "var(--red-text-2)" : "" }}>
            {(rowData["Count Out"] || 0) + (rowData["Missing Count Out"] || 0)}
          </div>
        </Tooltip>
      ),
    },
    ...(shouldIncludeMissingData
      ? [
          {
            title: "Missing Count Out",
            cellStyle: {
              display: "none",
            },
            headerStyle: {
              display: "none",
            },
            field: "Missing Count Out",
            searchable: false,
          },
        ]
      : []),
  ]

  // converting data to suitable format that nivo bar graph accepts
  // using the appropriate formatting function based on the sent data type
  const lineGraphData = useMemo(() => {
    if (!gateLogsLoading && counterLogs && endDate) {
      const lineGraphTableData: OverTimeTableData[] = calculateSumPerTime(
        counterLogs?.entities_logs,
        selectedDay,
        entranceEntities,
        missingData
      )
      const lineGraphData: LineGraphData[] = convertDataToHourlyLineGraph(lineGraphTableData, dataType)
      return { lineGraphTableData, lineGraphData }
    }
  }, [counterLogs, dataType, endDate, entranceEntities, gateLogsLoading, missingData, selectedDay])

  const lineGraphTableColumns: TableColumn[] =
    dataType !== "Gates counts"
      ? [
          {
            title: "Date/Time",
            field: "timestamp",
            searchable: false,
            render: (rowData: OverTimeTableData) => (
              <div style={{ color: rowData?.isModified ? "var(--red-text-2)" : "" }}>
                {formatTimestampForTable(rowData.timestamp, timeGrain as string)}
              </div>
            ),
          },
          {
            title: dataType,
            field: dataType,
            searchable: false,
            render: (rowData: OverTimeTableData) => (
              <div>{rowData[dataType as "Dwelling counts" | "Dwelling time"] || 0}</div>
            ),
          },
        ]
      : [
          {
            title: "Date/Time",
            field: "timestamp",
            searchable: false,
            render: (rowData: OverTimeTableData) => (
              <div style={{ color: rowData?.isModified ? "var(--red-text-2)" : "" }}>
                {formatTimestampForTable(rowData.timestamp, timeGrain as string)}
              </div>
            ),
          },
          {
            title: "Count In",
            field: "Count In",
            searchable: false,
            render: (rowData: OverTimeTableData) => (
              <Tooltip
                variant="secondary"
                placement="top"
                title={
                  rowData?.isModified ? (
                    <GraphTooltip
                      missingInfo={rowData?.missingInfo}
                      isTableTooltip
                      originalCountIn={rowData?.["Count In"]}
                      originalCountOut={rowData?.["Count Out"]}
                    />
                  ) : (
                    ""
                  )
                }
              >
                <div style={{ color: rowData?.isModified ? "var(--red-text-2)" : "" }}>{rowData["Count In"] || 0}</div>
              </Tooltip>
            ),
          },
          {
            title: "Count Out",
            field: "Count Out",
            searchable: false,
            render: (rowData: OverTimeTableData) => (
              <Tooltip
                variant="secondary"
                placement="top"
                title={
                  rowData.isModified ? (
                    <GraphTooltip
                      missingInfo={rowData?.missingInfo}
                      isTableTooltip
                      originalCountIn={rowData?.["Count In"]}
                      originalCountOut={rowData?.["Count Out"]}
                    />
                  ) : (
                    ""
                  )
                }
              >
                <div style={{ color: rowData.isModified ? "var(--red-text-2)" : "" }}>{rowData["Count Out"] || 0}</div>
              </Tooltip>
            ),
          },
          ...(shouldIncludeMissingData
            ? [
                {
                  title: "Missing Data",
                  cellStyle: {
                    display: "none",
                  },
                  headerStyle: {
                    display: "none",
                  },
                  field: "isModified",
                  searchable: false,
                },
              ]
            : []),
        ]

  return (
    <div className={styles.wrapper}>
      {!isLive && (
        <WeekDayFilter
          handleSelectDay={handleDaySelected}
          selectedDay={selectedDay}
          startDate={startDate}
          endDate={endDate}
          interval={interval}
        />
      )}
      {!isLive && <Insights data={formattedInsightsData} loading={insightsDataLoading} />}
      <div className={styles.titleWrapper}>
        <Typography variant="h3-bold" variantColor={2}>
          Stats
        </Typography>
        <div className={styles.switches}>
          <Switch checked={shouldIncludeStaff} onChange={handleSwitchStaffInclusion} label="Include staff" />
          {!isLive && (
            <Switch
              checked={shouldIncludeMissingData}
              onChange={handleSwitchMissingData}
              label="Include missing data"
            />
          )}
        </div>
      </div>
      <Grid container spacing={3}>
        <Grid item lg={2.8} md={4} sm={12} xs={12}>
          <NumericStats
            isLive={isLive}
            logsData={counterLogs?.entities_logs}
            loading={[gateLogsLoading, lastWeekLoading].some((element) => element === true)}
            lastWeekData={isLive ? lastWeekLogs?.entities_logs : undefined}
            visitorsRef={refs?.visitorsRef}
            occRef={refs?.occupancyRef}
            entityGroupsCounts={{
              avg_groups_size: counterLogs?.avg_groups_size,
              groups_count: counterLogs?.total_groups_count,
              counts_without_group: counterLogs?.counts_without_group,
              counts_in_groups: counterLogs?.counts_in_groups,
            }}
          />
        </Grid>
        <Grid item lg={9.2} md={8} sm={12} xs={12}>
          <DataOverTimeCard
            graphProps={{
              data: lineGraphData?.lineGraphData,
              interval: interval,
              hasCheckbox: dataType === "Gates counts",
            }}
            tableProps={{
              columns: lineGraphTableColumns,
              data: lineGraphData?.lineGraphTableData,
            }}
            timeGrain={timeGrain}
            isLoading={[gateLogsLoading, entranceEntitiesLoading, missingDataLoading].some(
              (element) => element === true
            )}
            reference={refs?.lineGraphRef}
            shouldIncludeMissingData={shouldIncludeMissingData}
            dataType={dataType}
            setDataType={setDataType}
            hasDataTypeSelect
          />
        </Grid>
        <Grid item xs={12}>
          {/* Bar Chart Top Performing Gates */}
          <PaginatedBarGraph
            endDate={endDate}
            startDate={startDate}
            data={barGraphData}
            isLoading={[gateLogsLoading, entranceEntitiesLoading, missingDataLoading].some(
              (element) => element === true
            )}
            title="Gates Performance"
            reference={refs?.gatesGraphRef}
            key={isLive ? "live" : "history"}
            shouldIncludeMissingData={shouldIncludeMissingData}
            graphProps={{
              keys: shouldIncludeMissingData ? ["Count In", "Missing Count In"] : ["Count In"],
              indexBy: "entity",
            }}
            tableProps={{ columns: barGraphTableColumns }}
          />
        </Grid>
        {/* Advanced Analytics for interval > 28 days */}
        {!isLive && (
          <Fragment>
            <Grid item lg={6} md={12} sm={12} xs={12}>
              <AvgCountWeek
                logsData={avgCounterLogs?.entities_logs}
                loading={avgLogsLoading}
                interval={interval!}
                reference={refs?.avgGraphRef}
              />
            </Grid>
            <Grid item lg={6} md={12} xs={12} sm={12}>
              <HourlyAvgData
                logsData={avgCounterLogs?.entities_logs}
                loading={avgLogsLoading}
                interval={interval!}
                reference={refs?.hourlyAvgGraphRef}
                dayToFilter={selectedDay}
              />
            </Grid>
            <Grid item xs={12}>
              <TotalCountsHeatmap
                logsData={avgCounterLogs?.entities_logs}
                loading={avgLogsLoading}
                interval={interval!}
                reference={refs?.heatmapGraphRef}
              />
            </Grid>
          </Fragment>
        )}
      </Grid>
    </div>
  )
}
export default CounterGates
