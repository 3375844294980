import { FC, useCallback, useEffect } from "react"

import { NotificationUtils, Typography } from "@synapse-analytics/synapse-ui"

import NoCamera from "../assets/NoCamera.svg?react"
import NoHistoryLogs from "../assets/NoHistoryLogs.svg?react"

import styles from "./Placeholder.module.scss"

interface Props {
  alertMessage?: string
  openSnackbar?: boolean
  selectionType?: "history" | "camera"
  description?: string
  isScreenPlaceholder?: boolean
  svgHeight?: string
  svgWidth?: string
}
const Placeholder: FC<Props> = ({
  alertMessage,
  selectionType = "history",
  description,
  isScreenPlaceholder,
  svgWidth = "182px",
  svgHeight = "220px",
  openSnackbar,
}) => {
  const EmptyStates = {
    history: <NoHistoryLogs className={styles.placeholder} height={svgHeight} width={svgWidth ?? ""} />,
    camera: <NoCamera className={styles.placeholder} height={svgHeight} width={svgWidth ?? ""} />,
  }

  const showAlert = useCallback(() => {
    if (alertMessage && openSnackbar) {
      NotificationUtils.toast(alertMessage, {
        severity: "warning",
      })
    }
  }, [alertMessage, openSnackbar])

  useEffect(() => {
    showAlert()
  }, [showAlert])

  return (
    <div
      className={styles.placeholderWrapper}
      style={{
        height: isScreenPlaceholder ? "70vh" : "100%",
      }}
    >
      {EmptyStates[selectionType]}
      <Typography variant="label-bold" variantColor={2}>
        {description}
      </Typography>
    </div>
  )
}
export default Placeholder
