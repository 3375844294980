import { useState, useEffect, useMemo, useCallback } from "react"
import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom"

import Fade from "@mui/material/Fade"

import Chatbot from "../../components/Chatbot/Chatbot"
import { browserHistoryListener } from "../../history"
import { routes } from "../../routes/routes"
import { useBranchesStore } from "../../store"
import { getFirstAuthorizedScreen } from "../../utils/DashboardUtils"
import Auth from "../../utils/auth"
import APIIntegration from "../APIIntegration/APIIntegration"
import AllUsers from "../AdminPanel/AllUsers"
import UserAddEdit from "../AdminPanel/UserAddEdit"
import BranchesAnalytics from "../BranchesAnalytics/BranchesAnalytics"
import CVC from "../CVC/CVC"
import CameraDetails from "../Cameras/CameraDetails/CameraDetails"
import CamerasList from "../Cameras/CamerasList"
import CampaignContextProvider from "../CampaignPlanner/CampaignContext/CampaignContext"
import CampaignDetaiLsWrapper from "../CampaignPlanner/CampaignDetails/CampaignDetaiLsWrapper"
import CampaignPlanner from "../CampaignPlanner/CampaignPlanner"
import CarsAnalytics from "../Cars/CarsAnalytics/CarsAnalytics"
import CarsHistory from "../Cars/CarsHistory/CarsHistory"
import CarsListsScreen from "../Cars/CarsLists/CarsListsScreen"
import CheckoutCounter from "../Cashiers/CheckoutCounter"
import CategoriesList from "../CategoriesManagement/CategoriesList"
import Corridors from "../Corridors/Corridors"
import DwellingArea from "../DwellingAreas/DetailsPage/DwellingArea"
import DwellingAreas from "../DwellingAreas/DwellingAreas"
import EntitiesAndRegions from "../EntitiesAndRegions/EntitiesAndRegions"
import EntranceGates from "../EntranceGates/EntranceGates"
import CounterGates from "../EntranceGates/components/CounterGates"
import FloorsList from "../Floors/FloorsList"
import FloorsContextProvider from "../Floors/floorsContext/FloorsContext"
import CamerasHeatmap from "../Heatmap/CamerasHeatmap/CamerasHeatmap"
import HeatmapLogs from "../Heatmap/CamerasHeatmap/HeatmapLogs"
import Heatmap from "../Heatmap/FloorsHeatmap/Heatmap"
import LiveStreams from "../LiveStreams/LiveStreams"
import CamerasMCP from "../MCP/CamerasMCP/CamerasMCP"
import FloorsMCP from "../MCP/FloorsMCP/FloorsMCP"
import MissingDataList from "../MissingData/MissingDataList"
import NodesDownAnalytics from "../Nodes/DownAnalytics/NodesDownAnalytics"
import NodesList from "../Nodes/NodesList"
import SecurityEvents from "../SecurityEvents/SecurityEvents"
import NotAllowed from "../StatusScreens/NotAllowed/NotAllowed"
import PageNotFound from "../StatusScreens/NotFound/NotFound"
import SubscriptionEnded from "../StatusScreens/SubscriptionEnded/SubscriptionEnded"
import Tenants from "../Tenants/Tenants"
import DetailsPage from "../TenantsList/DetailsPage/DetailsPage"
import TenantsList from "../TenantsList/TenantsList"
import Sidebar from "./components/Sidebar"
import TopBar from "./components/TopBar"

import styles from "./Dashboard.module.scss"

function Dashboard() {
  const [landingScreen, setLandingScreen] = useState()
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [persona, setPersona] = useState("analytics")
  const [isMultipleAnalytics] = useBranchesStore((state) => [state.isMultipleAnalytics])

  const navigate = useNavigate()

  // get permissions directly from local storage
  const permissions = JSON.parse(localStorage.getItem("permissions"))

  // extract available personas from user permissions
  const availablePersonas = useMemo(() => {
    const processedPermissions = permissions?.map((permission) => permission?.replace(/_/g, " "))
    return {
      hasAnalytics: processedPermissions?.some((perm) => perm?.includes("analytics")),
      hasAdmin: processedPermissions?.some((perm) => perm?.includes("admin")),
      hasMarketing: processedPermissions?.some((perm) => perm?.includes("marketing")),
      hasSecurity: processedPermissions?.some((perm) => perm?.includes("security")),
    }
  }, [permissions])

  // listening to route changes, to change page title
  // and to utilize GA
  const location = useLocation()
  useEffect(() => {
    browserHistoryListener(location)
  }, [location])

  // check available personas by checking available permissions
  // then set the proper landing screen according to first authorized screen for that persona

  useEffect(() => {
    if (availablePersonas.hasAnalytics) {
      const firstAuthScreen = getFirstAuthorizedScreen("analytics")
      setLandingScreen(firstAuthScreen)
    } else if (availablePersonas.hasAdmin) {
      const firstAuthScreen = getFirstAuthorizedScreen("admin")
      setLandingScreen(firstAuthScreen)
    } else if (availablePersonas.hasMarketing) {
      const firstAuthScreen = getFirstAuthorizedScreen("marketing")
      setLandingScreen(firstAuthScreen)
    } else if (availablePersonas.hasSecurity) {
      const firstAuthScreen = getFirstAuthorizedScreen("security")
      setLandingScreen(firstAuthScreen)
    } else {
      navigate(`/${routes?.noPermissions}`)
    }
  }, [
    availablePersonas.hasAdmin,
    availablePersonas.hasAnalytics,
    availablePersonas.hasMarketing,
    availablePersonas.hasSecurity,
    navigate,
  ])

  // called when user click on one of he user available personas
  // to update active persona and navigate to first authorized screen in that persona
  const togglePersonas = (persona) => {
    const firstAuthScreen = getFirstAuthorizedScreen(persona)
    navigate(firstAuthScreen)
  }

  const handleToggleDrawer = () => {
    setIsDrawerOpen((prevValue) => !prevValue)
  }

  const handleCloseDrawer = useCallback(() => {
    setIsDrawerOpen(false)
  }, [])

  const shouldHideNavigation =
    location.pathname === `/${routes?.[404]}` || location.pathname === `/${routes?.noPermissions}`

  return (
    <div>
      <Fade in={true} timeout={1500}>
        {Auth.subscriptionEnded() === true ? (
          <div>
            <Routes>
              <Route path="*" element={<Navigate to={`${routes?.subscriptionEnd}`} replace />} />
              <Route path={`${routes?.subscriptionEnd}`} element={<SubscriptionEnded />} />
            </Routes>
          </div>
        ) : (
          <div>
            {!shouldHideNavigation && (
              <TopBar
                persona={persona}
                togglePersonas={togglePersonas}
                availablePersonas={availablePersonas}
                handleToggleDrawer={handleToggleDrawer}
              />
            )}

            <div className={styles.root}>
              {/* Sidebar Navigation */}
              {!shouldHideNavigation && !isMultipleAnalytics && (
                <Sidebar
                  availablePersonas={availablePersonas}
                  persona={persona}
                  setPersona={setPersona}
                  handleToggleDrawer={handleToggleDrawer}
                  handleCloseDrawer={handleCloseDrawer}
                  isDrawerOpen={isDrawerOpen}
                />
              )}
              <main
                className={styles.content}
                style={{
                  padding: location.pathname === `/${routes?.[404]}` ? "0px" : "24px",
                }}
              >
                {Auth.getChatbotPermission() && !shouldHideNavigation && <Chatbot />}

                <Routes>
                  <Route path="*" element={<Navigate to={`/${routes?.[404]}`} />} />
                  <Route path={`/`} element={<Navigate to={`/${routes?.home}`} replace />} />
                  <Route path={`/${routes?.home}`} element={<Navigate to={landingScreen} replace />} />
                  <Route path={`/${routes?.[404]}`} element={<PageNotFound />} />

                  <Route path={`${routes?.noPermissions}`} element={<NotAllowed noPermissions={true} />} />

                  {Auth.getPeopleCountPermission() && (
                    <Route key="entrance-gates" path={`/${routes?.gates}/*`} element={<EntranceGates />}>
                      <Route key="gates-live" path={`${routes?.liveGates}`} element={<CounterGates isLive={true} />} />
                    </Route>
                  )}

                  <Route
                    key="multiple-branches"
                    path={`${routes?.branchesAnalytics}`}
                    element={<BranchesAnalytics />}
                  />

                  {Auth.getPeopleCountPermission() && (
                    <Route path={`/${routes?.gates}`} element={<Navigate to={`${routes?.liveGates}`} replace />} />
                  )}
                  {Auth.getPeopleCountPermission() && (
                    <Route key="tenants" path={`${routes?.tenants}`} element={<Tenants />} />
                  )}
                  {Auth.getPeopleCountPermission() && (
                    <Route key="tenants-list" path={`${routes?.tenantsList}`} element={<TenantsList />} />
                  )}
                  {Auth.getPeopleCountPermission() && (
                    <Route key="dwelling-areas" path={`${routes?.dwellingAreas}`} element={<DwellingAreas />} />
                  )}
                  {Auth.getPeopleCountPermission() && (
                    <Route key="corridors" path={`${routes?.corridors}`} element={<Corridors />} />
                  )}

                  {Auth.getPeopleCountPermission() && (
                    <Route key="cashiers" path={`${routes?.checkoutCounter}`} element={<CheckoutCounter />} />
                  )}

                  {Auth.getMissingDataPermission() && (
                    <Route key="missing-data-list" path={`${routes?.missingData}`} element={<MissingDataList />} />
                  )}

                  {Auth.getPeopleCountPermission() && (
                    <Route
                      key="tenant-details-page"
                      path={`${routes?.tenantsList}/${routes?.tenantDetails}/:id`}
                      element={<DetailsPage />}
                    />
                  )}

                  {Auth.getPeopleCountPermission() && (
                    <Route
                      key="dwelling-area-details-page"
                      path={`${routes?.dwellingAreas}/${routes?.dwellingAreaDetails}/:id`}
                      element={<DwellingArea />}
                    />
                  )}

                  {Auth.getRegionsPermission() && (
                    <Route
                      key="entities-and-regions"
                      path={`${routes?.entitiesAndRegions}`}
                      element={<EntitiesAndRegions />}
                    />
                  )}

                  {Auth.getNodesPermission() && (
                    <Route key="nodes" path={`${routes?.nodesList}`} element={<NodesList />} />
                  )}

                  {Auth.getNodesPermission() && (
                    <Route
                      key="node-down-analytics"
                      path={`${routes?.nodeDownAnalytics}/:id`}
                      element={<NodesDownAnalytics />}
                    />
                  )}

                  {Auth.getCategoriesPermissions() && (
                    <Route key="categories-list" path={`${routes?.categoriesList}`} element={<CategoriesList />} />
                  )}

                  {Auth.getCarsLogsSecurityPermission() && (
                    <Route key="cars" path={`${routes?.carsLogs}`} element={<CarsHistory />} />
                  )}

                  {Auth.getCarsAnalyticsPermission() && (
                    <Route key="cars-analytics" path={`${routes?.carsAnalytics}`} element={<CarsAnalytics />} />
                  )}
                  {Auth.getCarsAdminPermission() && (
                    <Route key="cars-lists" path={`${routes?.carsList}`} element={<CarsListsScreen />} />
                  )}
                  {Auth.getSecurityCameraStreamPermissions() && (
                    <Route key="live-streams" path={`${routes?.liveStreams}`} element={<LiveStreams />} />
                  )}

                  {Auth.getCamerasPermission() && (
                    <Route key="cameras-list" path={`${routes?.cameraList}`} element={<CamerasList />} />
                  )}

                  {Auth.getCamerasPermission() && (
                    <Route key="camera-details" path={`${routes?.cameraDetails}/:id`} element={<CameraDetails />} />
                  )}

                  {Auth.getCamerasPermission() && (
                    <Route key="camera-view-changes" path={`${routes?.cameraViewChanges}`} element={<CVC />} />
                  )}

                  {Auth.getFloorPermission() && (
                    <Route
                      key="floors-list"
                      path={`${routes?.floors}`}
                      element={
                        <FloorsContextProvider>
                          <FloorsList />
                        </FloorsContextProvider>
                      }
                    />
                  )}

                  {Auth.getHeatmapPermission() && (
                    <Route key="heatmap" path={`floors-heatmap/*`} element={<Heatmap />}>
                      <Route key="heatmap-logs" path={`${routes?.heatmapHistory}`} element={<HeatmapLogs />} />
                    </Route>
                  )}
                  {Auth.getHeatmapPermission() && (
                    <Route key="camera-heatmap" path={`${routes?.cameraHeatmap}`} element={<CamerasHeatmap />} />
                  )}
                  {Auth.getMcpPermissions() && (
                    <Route key="floors-mcp" path={`${routes?.floorsMCP}`} element={<FloorsMCP />} />
                  )}
                  {Auth.getMcpPermissions() && (
                    <Route key="cameras-mcp" path={`${routes?.camerasMCP}`} element={<CamerasMCP />} />
                  )}

                  {Auth.getUsersPermission() && (
                    <Route key="all-users" path={`${routes?.users}`} element={<AllUsers />} />
                  )}

                  {Auth.getUsersPermission() && (
                    <Route key="users-add" path={`${routes?.createUser}`} element={<UserAddEdit />} />
                  )}

                  {Auth.getUsersPermission() && (
                    <Route key="user-edit" path={`${routes?.users}/:id`} element={<UserAddEdit />} />
                  )}

                  {Auth.getAPIIntegrationPermission() && (
                    <Route key="api-integration" path={`${routes?.apiIntegration}`} element={<APIIntegration />} />
                  )}

                  {/* Campaign Planner */}
                  <Route
                    key="campaign-planner"
                    path={`${routes?.campaign}`}
                    element={
                      <CampaignContextProvider>
                        <CampaignPlanner />
                      </CampaignContextProvider>
                    }
                  />

                  {Auth.getCampaignPermission() && (
                    <Route
                      key="campaign"
                      path={`${routes?.campaign}/:id`}
                      element={
                        <CampaignContextProvider>
                          <CampaignDetaiLsWrapper />
                        </CampaignContextProvider>
                      }
                    />
                  )}
                  {/* Security */}
                  {Auth.getSecurityEventsPermissions() && (
                    <Route
                      key="security-incidents"
                      path={`${routes?.securityIncidents}`}
                      element={<SecurityEvents />}
                    />
                  )}
                </Routes>
              </main>
            </div>
          </div>
        )}
      </Fade>
    </div>
  )
}

export default Dashboard
