import { formatDuration, intervalToDuration } from "date-fns"
import moment from "moment"

import { getApiBaseUrl } from "../lib/axios"
import { FormattedDurationLog } from "../types/Custom/Interfaces"
import { definitions } from "../types/Generated/apiTypes"
import Auth from "./auth"

type CarVisit = definitions["CarDailyVisitsDuration"]
type CarCounterLog = definitions["CarCounterLog"]

/**
 * Calculates the sum of car counts over time from a list of counter logs.
 *
 * @param {Array<{ logs: CarCounterLog[] }>} counterLogs - An array of objects, each containing an array of car counter logs.
 * @returns {Array<{ "Count In": number; "Count Out": number; Difference: number; timestamp: string }>}
 * An array of objects, each representing the total "Count In", "Count Out", and their "Difference" at each timestamp.
 */
export function calculateCarsSumPerTime(
  counterLogs: { logs: CarCounterLog[] }[]
): { "Count In": number; "Count Out": number; Difference: number; timestamp: string }[] {
  const arr: { "Count In": number; "Count Out": number; Difference: number; timestamp: string }[] = []
  const timeDict: { [key: string]: number } = {}
  counterLogs.forEach((elem) => {
    elem.logs.forEach((log) => {
      if (log.timestamp in timeDict) {
        const existingObj = arr[timeDict[log.timestamp]]
        existingObj["Count In"] += log.count_in_sum
        existingObj["Count Out"] += log.count_out_sum
        existingObj["Difference"] += log.count_in_sum - log.count_out_sum
      } else {
        const newObj = { "Count In": 0, "Count Out": 0, Difference: 0, timestamp: log.timestamp }
        newObj["Count In"] = log.count_in_sum
        newObj["Count Out"] = log.count_out_sum
        newObj["Difference"] = log.count_in_sum - log.count_out_sum
        timeDict[newObj.timestamp] = arr.length
        arr.push(newObj)
      }
    })
  })
  // Sort timestamp
  arr.sort((a, b) => (a.timestamp > b.timestamp ? 1 : -1))
  return arr
}

/**
 * Converts car counter logs into a format suitable for an hourly line graph.
 *
 * @param {Array<{ logs: CarCounterLog[] }>} counterLogs - An array of objects, each containing an array of car counter logs.
 * @returns {Array<{ id: string; data: { x: Date; y: number }[] }>}
 * An array of objects, each with an 'id' and 'data' array. The 'data' array contains objects with 'x' as a Date and 'y' as a number,
 * representing the count of cars at each timestamp.
 */
export function convertCarsDataToHourlyLineGraph(
  counterLogs: { logs: CarCounterLog[] }[]
): { id: string; data: { x: Date; y: number }[] }[] {
  const arr = calculateCarsSumPerTime(counterLogs)
  let arr2 = [
    { id: "Count Out", data: [{ x: new Date(), y: 0 }] },
    { id: "Count In", data: [{ x: new Date(), y: 0 }] },
    { id: "Occupancy", data: [{ x: new Date(), y: 0 }] },
  ]
  // Resetting array after type declaration
  arr2 = [
    { id: "Count Out", data: [] },
    { id: "Count In", data: [] },
    { id: "Occupancy", data: [] },
  ]
  let occupancy = 0
  arr.forEach((elem) => {
    occupancy += elem["Count In"] - elem["Count Out"]
    arr2[2].data.push({ x: new Date(elem.timestamp), y: occupancy > 0 ? occupancy : 0 })
    arr2[1].data.push({ x: new Date(elem.timestamp), y: elem["Count In"] })
    arr2[0].data.push({ x: new Date(elem.timestamp), y: elem["Count Out"] })
  })
  return arr2
}

/**
 * Formats graph data for visualization by converting logs into a structured format.
 * Highlights the day with the highest average time spent.
 *
 * @param {Array<{ day: string; avg_time_spent: number }>} logs - An array of log objects, each containing a day and the average time spent in seconds.
 * @returns {FormattedDurationLog[]} - An array of formatted duration logs, each with a date, duration, and color.
 */
export const formatGraphData = (logs: { day: string; avg_time_spent: number }[]): FormattedDurationLog[] => {
  let arr: FormattedDurationLog[] = []
  if (logs) {
    logs.forEach((obj) => {
      const totalTimeSpentInSeconds = obj.avg_time_spent
      const row: FormattedDurationLog = {
        date: obj.day,
        duration: totalTimeSpentInSeconds,
        color: "var(--blue-background-2)",
      }
      arr.push(row)
    })
    if (arr.length > 0) {
      // Find index of the highest duration weekday
      const maxDurationIndex = arr.reduce((maxIndex, obj, index, arr) => {
        return obj.duration > arr[maxIndex].duration ? index : maxIndex
      }, 0)
      // Highlight the highest duration weekday bar
      arr[maxDurationIndex].color = "var(--blue-background-1)"
    }
  }
  return arr
}

/**
 * Exports a report of car logs based on specified filters.
 *
 * @param startDate - The start date for the report in YYYY-MM-DD format.
 * @param endDate - The end date for the report in YYYY-MM-DD format.
 * @param licensePlate - The license plate to filter the car logs.
 * @param carColor - The color of the car to filter the logs.
 * @param carState - The state of the car to filter the logs.
 * @param listOfInterest - A specific list to filter the car logs.
 * @param carBrand - The brand of the car to filter the logs.
 * @param carType - The type of the car to filter the logs.
 * @returns A promise that resolves to a CSV string of the car logs or void if an error occurs.
 */
export const ExportReport = async (
  startDate: string,
  endDate: string,
  licensePlate: string,
  carColor: string,
  carState: string,
  listOfInterest: string,
  carBrand: string,
  carType: string
): Promise<string | void> => {
  try {
    const target = `${getApiBaseUrl()}/api/cars/logs/list/report/?search=${licensePlate}&car_color=${carColor}&state=${carState}&list_of_interest=${listOfInterest}&brand=${carBrand}&car_type=${carType}&start_dt=${startDate}&end_dt=${endDate}`
    const res = await fetch(target, {
      method: "get",
      headers: {
        "content-type": "text/csv;charset=UTF-8",
        Authorization: "Bearer " + Auth.getAccessToken(),
      },
    })

    if (res.status === 200) {
      const data = await res.text()
      return data
    } else {
      console.log(`Error code ${res.status} when loading cars CSV`)
      return Promise.reject(new Error("Error fetching cars report"))
    }
  } catch (err) {
    console.log(err)
  }
}

export const carsColors = [
  { value: "", color: "All Colors" },
  { value: "w", color: "White" },
  { value: "k", color: "Black" },
  { value: "bg", color: "Blue-Green" },
  { value: "gs", color: "Gray-Silver" },
  { value: "ro", color: "Red-Orange" },
  { value: "yb", color: "Yellow-Brown" },
]

export const carsBrands = [
  "audi",
  "bmw",
  "brilliance",
  "byd",
  "chery",
  "chevrolet",
  "citroen",
  "fiat",
  "ford",
  "geely",
  "honda",
  "hyundai",
  "jeep",
  "kia",
  "mazda",
  "mercedes",
  "mg",
  "mini",
  "mitsubishi",
  "nissan",
  "peugeot",
  "renault",
  "seat",
  "skoda",
  "subaru",
  "suzuki",
  "toyota",
  "volkswagen",
  "volvo",
  "opel",
]

export const bodyTypes = [
  { value: "", label: "All Types" },
  { value: "car", label: "Car" },
  { value: "trk", label: "Truck" },
  { value: "bus", label: "Bus" },
  { value: "moto", label: "Motorcycle" },
  { value: "bike", label: "Bicycle" },
]

export const carsStates = [
  { value: "", label: "In & Out" },
  { value: "0", label: "In Cars" },
  { value: "1", label: "Out Cars" },
]

/**
 * Sorts an array of car counts in descending order and assigns colors based on their position.
 *
 * @param {Array<{ count: number; color?: string }>} carCounts - The array of car counts to sort and colorize.
 * @returns {Array<{ count: number; color?: string }>} - The sorted and colorized array of car counts.
 */
export function sortAndColorizeCarCounts(
  carCounts: { count: number; color?: string }[]
): { count: number; color?: string }[] {
  if (carCounts && carCounts.length > 0) {
    // Sort the array by the 'count' property in descending order
    carCounts.sort((a, b) => b.count - a.count)

    // Set the color property based on the index in the sorted array
    carCounts.forEach((carCount, index) => {
      carCount.color = index === 0 ? "var(--primary-background-default)" : "var(--blue-background-2)"
    })
  }

  return carCounts
}

/**
 * Parses a duration string into an object with hours, minutes, and seconds.
 *
 * @param {string} duration - The duration string to parse (format "HH:mm:ss").
 * @returns {object} - An object with hours, minutes, and seconds.
 */
const parseDuration = (duration: string): { hours: number; minutes: number; seconds: number } => {
  const [hours, minutes, seconds] = duration.split(":").map(Number)
  return { hours, minutes, seconds }
}

/**
 * Converts a duration from seconds to a human-readable format like "1 hour and 13 minutes".
 *
 * @param {number|string} duration - The duration in seconds.
 * @returns {string} - The human-readable duration.
 */
export function convertToHumanReadableDuration(duration: number | string): string {
  // Ensure duration is a number
  const seconds = typeof duration === "string" ? parseInt(duration, 10) : duration

  if (isNaN(seconds) || seconds <= 0) {
    return "No Data"
  }

  // Convert seconds to a Duration object
  const durationObj = intervalToDuration({ start: 0, end: seconds * 1000 })

  // Format the duration
  const formatted = formatDuration(durationObj, { format: ["hours", "minutes"] })

  return formatted || "less than a minute"
}

/**
 * Converts a duration string in the format "HH:mm:ss" to the total number of seconds.
 * If the duration is undefined or results in NaN, it returns 0.
 *
 * @param {string} duration - The duration string to convert (format "HH:mm:ss").
 * @returns {number} - The total number of seconds.
 */
export function visitDurationToSeconds(duration?: string): number {
  if (!duration) {
    return 0
  }

  const { hours, minutes, seconds } = parseDuration(duration)
  const totalSeconds = hours * 60 * 60 + minutes * 60 + seconds
  return isNaN(totalSeconds) ? 0 : totalSeconds
}

/**
 * Sorts car visits based on duration and formats the data.
 *
 * @param {Array} carData - Array of CarVisit objects.
 * @returns {Array} - Formatted and sorted data.
 */
export function processCarVisitData(visitData?: CarVisit[]): CarVisit[] {
  if (!visitData || (visitData && visitData?.length < 1)) {
    return []
  }

  // Find max duration without sorting data
  let maxDuration = 0
  visitData.forEach((visit) => {
    if (visit.duration && visit.duration > maxDuration) {
      maxDuration = visit.duration
    }
  })
  // Format the sorted data
  return visitData.map((c) => {
    return {
      ...c,
      date: moment(c.date).format("DD MMM, YYYY"),
      color:
        c.duration && c.duration === maxDuration && maxDuration > 0
          ? "var(--blue-background-1)"
          : "var(--blue-background-2)",
    }
  })
}
