import { FC, useState, Fragment } from "react"
import { useMutation, useQueryClient } from "react-query"

import DeleteIcon from "@mui/icons-material/DeleteOutlined"
import EditIcon from "@mui/icons-material/EditOutlined"
import { Card, CardMedia, CardActionArea } from "@mui/material"

import { Typography, Tag, NotificationUtils, Button } from "@synapse-analytics/synapse-ui"
import { shallow } from "zustand/shallow"

import { VisionAPI } from "../../../../API/VisionAPI"
import NoFrame from "../../../../assets/NoCamera.svg?react"
import WarningDialog from "../../../../components/WarningDialog"
import { useBranchesStore } from "../../../../store"
import { definitions } from "../../../../types/Generated/apiTypes"
import { getAuthBaseUrl } from "../../../../utils/auth"
import FloorAddEdit from "./FloorAddEdit"

import styles from "./FloorCard.module.scss"

type floorsList = definitions["FloorPlanList"]

interface Props {
  floor: floorsList
}

const API_URL = getAuthBaseUrl()

const Floor: FC<Props> = ({ floor }) => {
  const [openEdit, setOpenEdit] = useState(false)
  const [open, setOpen] = useState(false)
  const queryClient = useQueryClient()
  const [selectedBranch] = useBranchesStore(
    (state: { selectedBranch: number | null }) => [state.selectedBranch],
    shallow
  )

  const handleClose = () => setOpen(false)

  const handleCloseEdit = () => {
    setOpenEdit(false)
  }

  const { mutate: handleDeleteFloor, isLoading: isDeletingFloor } = useMutation(
    () => VisionAPI.deleteFloorPlan(floor?.id as number, selectedBranch as number),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("fetchFloors")
        NotificationUtils.toast(`${floor?.floor_name || "Floor"} has been deleted successfully`, {
          severity: "success",
        })
        handleClose()
      },
    }
  )
  const isSetupIncomplete = floor?.placed_cameras! > floor?.mapped_cameras!
  const isSetupComplete =
    floor?.mapped_cameras! !== 0 && floor?.placed_cameras! !== 0 && floor?.placed_cameras! === floor?.mapped_cameras!

  return (
    <Fragment>
      <Card className={styles.floorWrapper}>
        {/* floor name & action buttons */}
        <div className={styles.header}>
          <Typography variant="h3-bold" title={floor?.floor_name} style={{ width: "100%" }} noWrap>
            “{floor?.floor_name}”
          </Typography>
          <div className={styles.actionButtons}>
            <Button
              variant="ghost-secondary"
              size="small"
              onClick={() => setOpen(true)}
              startIcon={<DeleteIcon fontSize="small" />}
            />
            <Button
              variant="ghost-secondary"
              size="small"
              onClick={() => setOpenEdit(true)}
              startIcon={<EditIcon fontSize="small" />}
            />
          </div>
        </div>
        <Tag
          title="Setup status"
          color={isSetupIncomplete ? "red" : isSetupComplete ? "green" : "yellow"}
          size="xsmall"
          variant="filled"
          style={{ visibility: isSetupComplete ? "hidden" : "visible" }}
        >
          {isSetupIncomplete ? "Setup Incomplete" : isSetupComplete ? "Setup Complete" : "Setup Pending"}
        </Tag>
        <CardActionArea onClick={() => setOpenEdit(true)}>
          {floor?.image ? (
            <CardMedia className={styles.media} image={`${API_URL}${floor?.image}`} title={floor?.floor_name!} />
          ) : (
            <div className={styles.media}>
              <NoFrame />
              <Typography variant="label-regular" variantColor={2}>
                No Floor Plan
              </Typography>
            </div>
          )}
        </CardActionArea>
        <div className={styles.infoSection}>
          <Typography variant="label-bold" variantColor={2} gutterBottom>
            Cameras
          </Typography>
          <Typography variant="p" variantColor={2} gutterBottom>
            {floor?.placed_cameras && floor?.placed_cameras > 0
              ? `${floor?.placed_cameras} cameras active`
              : "No cameras placed"}
          </Typography>
        </div>

        {/* tags */}
        <div className={styles.infoSection}>
          <Typography variant="label-bold" variantColor={2} gutterBottom>
            Tags
          </Typography>
          <div className={styles.infoTags}>
            {floor?.tags && floor?.tags?.length > 0 ? (
              <Fragment>
                {floor?.tags?.slice(0, 4).map((tag, i) => (
                  <Tag
                    key={`${i}`}
                    title={tag}
                    className={styles.floorTags}
                    color="gray"
                    size="xsmall"
                    variant="filled"
                  >
                    {tag.length > 10 ? tag.slice(0, 9) + ".." : tag}
                  </Tag>
                ))}
                {floor?.tags?.length > 4 && (
                  <Typography variant="label-regular" className={styles.tagsCaption} display="inline">
                    + {floor?.tags?.length - 4} more
                  </Typography>
                )}
              </Fragment>
            ) : (
              <Typography variant="label-regular" variantColor={2}>
                No Tags Yet
              </Typography>
            )}
          </div>
        </div>
      </Card>
      <WarningDialog
        confirmationText={`delete floor ${floor?.floor_name}?`}
        onCancel={handleClose}
        onConfirm={handleDeleteFloor}
        isOpen={open}
        isDangerousAction
        actionTitle="Delete"
        isLoading={isDeletingFloor}
      />

      <FloorAddEdit edit floor={floor} handleClose={handleCloseEdit} open={openEdit} />
    </Fragment>
  )
}

export default Floor
