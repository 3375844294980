import React, { Fragment, useContext } from "react"
import { useQuery } from "react-query"

import HighlightOffIcon from "@mui/icons-material/Close"
import { Dialog, DialogContent } from "@mui/material"
import Slide from "@mui/material/Slide"
import { SlideProps } from "@mui/material/Slide/Slide"

import { Typography, Button } from "@synapse-analytics/synapse-ui"
import { AxiosError } from "axios"

import { VisionAPI } from "../../../API/VisionAPI"
import WarningDialog from "../../../components/WarningDialog"
import { IEntitiesSetup } from "../../../types/Custom/Interfaces"
import { definitions } from "../../../types/Generated/apiTypes"
import { EntitiesContext } from "./EntitiesContext/EntitiesContext"
import EntityForm from "./components/FirstStep/EntityForm"
import SelectAndDraw from "./components/SecondStep/SelectAndDraw"

import styles from "./EntitiesSetup.module.scss"

type Category = definitions["Category"]

const Transition = React.forwardRef(function Transition(props: SlideProps, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const EntitiesSetup = () => {
  const {
    formik,
    activeStep,
    isCancelMessageOpen,
    setActiveStep,
    isDialogOpen,
    setIsCancelMessageOpen,
    isLoading,
    handleCloseDialog,
    handleSaveOnlyForm,
    isEdit,
    hasMinimumFulfillment,
  } = useContext<IEntitiesSetup>(EntitiesContext)

  const { data: categories, isLoading: isLoadingCategories } = useQuery<Category[], AxiosError>(
    "fetchCategories",
    VisionAPI.fetchCategories
  )

  const handleNext = () => setActiveStep(activeStep + 1)
  const handleBack = () => setActiveStep(activeStep - 1)

  // closing confirmation message pop up
  const handleTriggerCloseConfirmation = () => {
    if (formik.dirty || activeStep > 0) setIsCancelMessageOpen(true)
    else handleCloseDialog()
  }

  const handleCancelCloseConfirmation = () => setIsCancelMessageOpen(false)

  return (
    <Fragment>
      <WarningDialog
        dialogTitle={`Region ${isEdit ? "editing" : "creation"} cancellation `}
        content="You have unsaved changes. Do you want to discard them?"
        isDangerousAction
        actionTitle="Discard Changes"
        isOpen={isCancelMessageOpen}
        onCancel={handleCancelCloseConfirmation}
        onConfirm={handleCloseDialog}
      />

      <Dialog
        onClick={(event) => event.stopPropagation()}
        open={isDialogOpen}
        scroll="paper"
        onClose={() => setIsCancelMessageOpen(true)}
        TransitionComponent={Transition}
        disableEscapeKeyDown
        PaperProps={{
          sx: {
            minWidth: activeStep === 0 ? "680px" : "1150px",
          },
        }}
      >
        <DialogContent className={styles.wrapper}>
          <div className={styles.header}>
            <Typography variant="h2-bold" className={styles.title}>
              {isEdit ? "Edit" : "Create"} {formik.values.type} {activeStep === 1 ? ` - (${formik.values.name}) ` : ""}
            </Typography>
            <HighlightOffIcon onClick={handleTriggerCloseConfirmation} className={styles.iconContainer} />
          </div>
          {/* Add/edit entity form*/}
          {activeStep === 0 ? (
            <EntityForm categories={categories} isLoadingCategories={isLoadingCategories} />
          ) : (
            // Select and draw step
            <SelectAndDraw />
          )}

          <div className={styles.actionButtons}>
            <Button
              variant="secondary"
              onClick={handleBack}
              style={{ visibility: activeStep === 0 ? "hidden" : "visible" }}
            >
              Back
            </Button>

            <div className={styles.proceedingButtons}>
              {isEdit && activeStep === 0 && (
                <Button variant="secondary" onClick={handleNext} disabled={!formik.isValid}>
                  Edit Region
                </Button>
              )}
              <Button
                variant="primary"
                onClick={activeStep === 0 ? (isEdit ? handleSaveOnlyForm : handleNext) : formik.submitForm}
                disabled={activeStep === 0 ? !(formik.isValid && formik.dirty) : !hasMinimumFulfillment}
                isLoading={isLoading}
              >
                <span style={{ marginRight: isLoading ? 10 : 0 }}>
                  {activeStep === 0 ? (isEdit ? "Save Changes" : "Next") : "Save"}
                </span>
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </Fragment>
  )
}
export default EntitiesSetup
