import { FC } from "react"

import { SwiperSlide } from "swiper/react"

import SwiperContainer from "../../../../components/SwiperContainer"
import { definitions } from "../../../../types/Generated/apiTypes"
import DragDropFloor from "./DragDropFloor"
import FloorVersionCard from "./FloorVersionCard"

type FloorPlanVersionList = definitions["FloorPlanVersionList"]

interface Props {
  floorVersions: FloorPlanVersionList[]
}

const FloorVersions: FC<Props> = ({ floorVersions }) => {
  return (
    <SwiperContainer slidesPerView={3} slidesToScroll={1} spaceBetweenSlides={8}>
      <SwiperSlide>
        <DragDropFloor edit textPlaceholder="Add New Version" />
      </SwiperSlide>
      {floorVersions &&
        floorVersions?.length > 0 &&
        floorVersions?.map((version) => (
          <SwiperSlide key={version?.id!}>
            <FloorVersionCard version={version} />
          </SwiperSlide>
        ))}
    </SwiperContainer>
  )
}

export default FloorVersions
