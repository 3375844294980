import React, { FC, useState, useEffect, useRef } from "react"

import PauseIcon from "@mui/icons-material/Pause"
import PlayArrowIcon from "@mui/icons-material/PlayArrow"
import SkipNextIcon from "@mui/icons-material/SkipNext"
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious"
import { Slider, Typography } from "@mui/material"

import { Chip } from "@synapse-analytics/synapse-ui"
import HeatmapJS from "keli-heatmap.js"

import { Mark } from "../../../types/Custom/Interfaces"
import { definitions } from "../../../types/Generated/apiTypes"
import { getAuthBaseUrl } from "../../../utils/auth"
import { calculateDailyLogs, calculateHourlyLogs, updateSliderMarks } from "../../../utils/heatmapUtils"
import { calculateStartValue } from "../../../utils/mcpUtils"

import styles from "./HeatmapTimeline.module.scss"

type HeatMapHourly = definitions["HeatMapHourly"] & { version_no?: number }
type HeatMapLogs = definitions["HeatMapLogs"]
type FloorPlanVersionList = definitions["FloorPlanVersionList"]
interface allData {
  version_no?: number
  timestamp: string
  logs: HeatMapLogs[]
}

interface Props {
  marks: Mark[]
  timeGrain?: "hourly" | "daily"
  firstLogs?: HeatMapHourly[]
  secondLogs?: HeatMapHourly[]
  heatmapLogs: HeatMapHourly[] | undefined
  floorPlanVersions?: FloorPlanVersionList[]
  isCameraHeatmap?: Boolean
  cameraSampleFrame?: string
}
const APIURL = getAuthBaseUrl()

const HeatmapTimeline: FC<Props> = ({
  heatmapLogs,
  timeGrain,
  marks,
  firstLogs,
  secondLogs,
  floorPlanVersions,
  isCameraHeatmap,
  cameraSampleFrame,
}) => {
  const imageRef = useRef<HTMLImageElement>(null)
  const [play, setPlay] = useState(false)
  const [clickPoint, setClickPoint] = useState(false)
  const [sliderValue, setSliderValue] = useState(0)
  const [versionNumber, setVersionNumber] = useState(
    firstLogs && firstLogs?.length > 0
      ? firstLogs[0].version_no
      : secondLogs && secondLogs?.length > 0
      ? secondLogs[0].version_no
      : 1
  )
  const [imageSize, setImageSize] = useState({ width: 736, height: 416 })
  const [allData, setAllData] = useState<allData[]>()
  const [allMarks, setAllMarks] = useState<Mark[]>()
  const [heatmapInstance, setHeatmapInstance] = useState<any>()
  const [maxValue, setMaxValue] = useState(0)
  const [startValues, setStartValues] = useState<number[]>([])
  const [currentFloorPlan, setCurrentFloorPlan] = useState<FloorPlanVersionList>()
  useEffect(() => {
    if (marks && marks?.length > 0) setAllMarks(marks)
  }, [marks])

  const handleSliderChange = (event: any, value: any) => {
    if (allMarks && allMarks?.length > 1) {
      setSliderValue(allMarks!.findIndex((mark) => mark.value === value))
      setClickPoint(true)
      // Get version number of floor plan
      setVersionNumber(allData![value]?.version_no!)
    }
  }

  const handleClick = () => {
    if (heatmapLogs && heatmapLogs.length > 0 && allMarks && allMarks?.length > 0) {
      setPlay(!play)
      setClickPoint(false)
      if (sliderValue === 24 || sliderValue >= 30) {
        setSliderValue(0)
      }
    }
  }

  //INIT heatmap instance
  useEffect(() => {
    setTimeout(() => {
      setHeatmapInstance(
        HeatmapJS.create({
          container: document.getElementById("heatmap")!,
          radius: 16,
        })
      )
      setSliderValue(0)
    }, 500)
    setSliderValue(1)
  }, [])

  // Play heatmap points
  useEffect(() => {
    if (heatmapInstance) {
      if (heatmapInstance.getData().data && heatmapInstance.getData().data.length > 0) {
        heatmapInstance.setData({ data: [] })
      }
      // Handle heatmap data
      if (heatmapLogs && heatmapLogs.length > 0) {
        let allData = timeGrain === "hourly" ? calculateHourlyLogs(heatmapLogs) : calculateDailyLogs(heatmapLogs)
        const filterData = allData?.filter((data) => (isCameraHeatmap ? data.timestamp : data?.version_no > -1))
        setAllData(filterData)
        updateSliderMarks(allMarks, filterData, timeGrain)

        let dataToDraw = filterData && filterData?.length > 0 ? filterData[sliderValue]?.logs : []
        let dataMax = 0
        filterData.forEach((elem: HeatMapHourly) => {
          elem.logs.forEach((elem: HeatMapLogs) => {
            if (dataMax < elem.value) {
              dataMax = elem.value
            }
          })
        })
        setMaxValue(dataMax)
        // Update heatmap
        let data = {
          max: dataMax * (allMarks && allMarks.length === 24 ? 0.2 : 1),
          data: dataToDraw,
        }

        if (dataToDraw && dataToDraw?.length > 0 && data) {
          heatmapInstance.setData(data)
        }
      }
    }
  }, [allMarks, heatmapInstance, heatmapLogs, sliderValue, timeGrain, isCameraHeatmap])

  // Filter marks to remove empty marks
  useEffect(() => {
    if (heatmapInstance) {
      setAllMarks(
        allMarks?.filter((marks) =>
          timeGrain === "hourly" ? marks?.label !== "Invalid date" && marks?.label : marks?.label !== "" && marks?.label
        )
      )
    }
    // eslint-disable-next-line
  }, [heatmapInstance, timeGrain])

  // handle play timeline and play button
  useEffect(() => {
    if (play && allMarks?.length !== 0 && allMarks !== undefined) {
      const timer = setInterval(() => {
        if (sliderValue === allData!.length - 1) {
          setSliderValue(0)
        } else {
          setSliderValue(sliderValue + 1)
        }
      }, 650)
      // Get version number of floor plan
      setVersionNumber(allData![sliderValue]?.version_no!)
      return () => clearTimeout(timer)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allMarks, play, sliderValue])
  const handleNextMark = () => {
    if (sliderValue === allData!.length - 1) {
      setSliderValue(0)
      // Get version number of floor plan
      setVersionNumber(allData![0]?.version_no!)
    } else {
      setSliderValue((prevValue) => prevValue + 1)
      setVersionNumber(allData![sliderValue + 1]?.version_no!)
    }
  }
  const handlePreviousMark = () => {
    if (sliderValue === 0) {
      setSliderValue(allData!.length - 1)
      // Get version number of floor plan
      setVersionNumber(allData![allData!.length - 1]?.version_no!)
    } else {
      setSliderValue((prevValue) => prevValue - 1)
      setVersionNumber(allData![sliderValue - 1]?.version_no!)
    }
  }

  useEffect(() => {
    const desiredFloorPlan = floorPlanVersions?.find((plan) => plan.version_no === versionNumber)
    setCurrentFloorPlan(desiredFloorPlan)
  }, [floorPlanVersions, versionNumber])
  useEffect(() => {
    const img = new Image()

    if (floorPlanVersions && currentFloorPlan?.image) {
      const floorImage = currentFloorPlan.image
      if (floorImage) {
        img.src = `${APIURL}${floorImage}`
        img.onload = () => {
          setImageSize({
            height: img.height,
            width: img.width,
          })
        }
      }
    }
  }, [currentFloorPlan, floorPlanVersions, versionNumber])

  useEffect(() => {
    const legendValues = calculateStartValue(0, maxValue)
    setStartValues(legendValues)
  }, [maxValue])

  return (
    <div
      className={styles.wrapper}
      style={{
        background: play
          ? "var(--base-background-1)"
          : "linear-gradient(0deg, var(--base-text-1) 0%, rgba(0, 0, 0, 0) 100%) , linear-gradient(180deg, rgba(0, 0, 0, 0.65) 0%, rgba(196, 196, 196, 0) 100%)",
      }}
    >
      <div className={styles.legendsWrapper}>
        <div style={{ display: "column" }}>
          <Typography className={styles.LegendsTitle}>
            Color: <br /> People Count in Area
          </Typography>
          <div className={styles.valuesAndSymbols}>
            <div className={styles.valuesLegendWrapper}>
              <div className={styles.valuesLegend}></div>
              <div className={styles.valuesAnnotation}>
                <Typography className={styles.minMaxValues}>0</Typography>
                {startValues.slice(1).map((value, index) => (
                  <Typography key={index} className={styles.minMaxValues}>
                    {Math.round(value).toLocaleString()}
                  </Typography>
                ))}
                <Typography className={styles.minMaxValues}>{maxValue.toLocaleString()}</Typography>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="heatmap"
        style={{
          height: `${imageSize.height}px`,
          width: `${imageSize.width}px`,
        }}
      >
        <img
          ref={imageRef}
          className={styles.floorPlan}
          style={{
            opacity: play || clickPoint ? 0.9 : 0.6,
          }}
          width={isCameraHeatmap ? 736 : imageSize.width}
          height={isCameraHeatmap ? 416 : imageSize.height}
          src={
            isCameraHeatmap && cameraSampleFrame
              ? cameraSampleFrame
              : floorPlanVersions && `${APIURL}${currentFloorPlan?.image!}`
          }
          alt="heatmapImage"
        />
      </div>

      <div className={styles.sliderWrapper} style={{ overflow: "hidden" }}>
        {/* floor version number */}
        {!isCameraHeatmap && (
          <div className={styles.versionWrapper}>
            {firstLogs && firstLogs?.length > 0 && (
              <Chip
                size="small"
                isSelected={versionNumber === firstLogs[0].version_no}
                clickable={false}
                className={styles.versionTitle}
              >
                Version {firstLogs[0].version_no}
              </Chip>
            )}
            {secondLogs && secondLogs?.length > 0 && (
              <Chip
                size="small"
                isSelected={versionNumber === secondLogs[0].version_no}
                clickable={false}
                className={styles.versionTitle}
              >
                Version {secondLogs[0].version_no}
              </Chip>
            )}
          </div>
        )}
        <div className={styles.playerWrapper}>
          <Slider
            sx={{
              "& .MuiSlider-markLabel": {
                background: play ? "rgba(255, 255, 255, 0.4)" : "var(--base-background-3)",
              },
              "& .MuiSlider-mark": {
                background: play ? "#929292" : "#0D0D0D",
              },
              "& .MuiSlider-markActive": {
                background: play ? "#929292" : "#0D0D0D",
              },
            }}
            classes={{
              root: styles.root,
              thumb: styles.thumb,
              mark: styles.mark,
              rail: styles.rail,
              track: styles.track,
              markLabel: styles.markLabel,
              markLabelActive: styles.markLabelActive,
              markActive: styles.markActive,
            }}
            disabled={heatmapLogs && heatmapLogs.length > 0 ? false : true}
            onChange={handleSliderChange}
            defaultValue={0}
            value={sliderValue}
            aria-labelledby="discrete-slider"
            step={null}
            max={allData && allData.length - 1}
            marks={allMarks && allMarks}
          />

          <div className={styles.playerControls}>
            <SkipPreviousIcon
              className={styles.playerControl}
              sx={{
                background:
                  allMarks && allMarks.length > 1 ? "var(--base-background-3)" : "var(--secondary-background-default)",
              }}
              onClick={handlePreviousMark}
            />
            {play ? (
              <PauseIcon
                className={styles.playIconSlider}
                sx={{
                  background:
                    allMarks && allMarks.length > 1
                      ? "var(--base-background-3)"
                      : "var(--secondary-background-default)",
                }}
                onClick={handleClick}
              />
            ) : (
              <PlayArrowIcon
                className={styles.playIconSlider}
                sx={{
                  background:
                    allMarks && allMarks.length > 1
                      ? "var(--base-background-3)"
                      : "var(--secondary-background-default)",
                }}
                onClick={handleClick}
              />
            )}
            <SkipNextIcon
              className={styles.playerControl}
              sx={{
                background:
                  allMarks && allMarks.length > 1 ? "var(--base-background-3)" : "var(--secondary-background-default)",
              }}
              onClick={handleNextMark}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default HeatmapTimeline
