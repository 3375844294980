import { FC, useContext, useEffect } from "react"
import { useQuery } from "react-query"

import { Snackbar, Alert, Grid } from "@mui/material"

import { InputText, Skeleton, Typography } from "@synapse-analytics/synapse-ui"
import { AxiosError } from "axios"

import { VisionAPI } from "../../../../API/VisionAPI"
import TagsList from "../../../../components/TagsList"
import { definitions } from "../../../../types/Generated/apiTypes"
import { FloorsContext } from "../../floorsContext/FloorsContext"
import DragDropFloor from "./DragDropFloor"
import FloorVersions from "./FloorVersions"

import styles from "./FloorDetails.module.scss"

type FloorPlanVersionList = definitions["FloorPlanVersionList"]
type FloorList = definitions["FloorPlanList"]
type Tag = definitions["Tag"]

interface Props {
  handleClose: () => void
  open: boolean
  edit?: boolean
  floor?: FloorList
}

const loadingPlaceholders = new Array(3).fill(null).map((r, i) => (
  <Grid item md={4} key={i}>
    <Skeleton variant="rectangular" height={230} style={{ width: "100%" }} />
  </Grid>
))

const FloorDetails: FC<Props> = ({ handleClose, open, edit, floor }) => {
  const { formik, floorEditSuccuss } = useContext(FloorsContext)
  const { data: floorVersions, isFetching } = useQuery<FloorPlanVersionList[], AxiosError>(
    ["fetchFloorVersions", floor?.id],
    async ({ queryKey }) => VisionAPI.fetchFloorPlanAllVersions(queryKey[1] as number),
    { enabled: Boolean(!!edit && !!open && !!floor?.id) }
  )

  // fetch all floors
  const { data: floorTags, isLoading: isLoadingTags } = useQuery<Tag[]>("fetchFloorTags", VisionAPI.fetchFloorTags)

  useEffect(() => {
    if (edit && open) {
      formik?.setFieldValue("floorName", floor?.floor_name!)
      formik?.setFieldValue("floorPreview", "")
      formik?.setFieldValue("floorId", floor?.id!)
      formik?.setFieldValue("tags", floor?.tags!)
      formik?.setFieldValue("floorEdit", true)
    }
    // eslint-disable-next-line
  }, [edit, open])

  useEffect(() => {
    if (edit && floorEditSuccuss) handleClose()
    // eslint-disable-next-line
  }, [edit, floorEditSuccuss])

  return (
    <div className={styles.wrapper}>
      <InputText
        id="floorName"
        label="Floor name"
        placeholder="E.g.Floor 1"
        fullWidth
        handleBlur={formik.handleBlur}
        error={formik.touched.floorName && Boolean(formik.errors.floorName) && formik.errors.floorName}
        handleChange={formik.handleChange}
        required
        value={formik?.values.floorName}
      />
      {/* Floor tags */}
      <TagsList
        formik={formik}
        isLoading={isLoadingTags}
        placeholder="Press enter to add tag"
        label="Floor Tags"
        options={floorTags!}
        fullWidth
        selectedTags={formik.values.tags}
      />

      {/* floor plan map preview */}
      <div className={styles.versionWrapper}>
        <Typography variant="a" variantColor={2} tooltip="Only one version can be activated">
          Upload floor plan*
        </Typography>
        {isFetching ? (
          <Grid container spacing={1}>
            {loadingPlaceholders}
          </Grid>
        ) : edit ? (
          <FloorVersions floorVersions={floorVersions!} />
        ) : (
          <DragDropFloor textPlaceholder="Drag & drop or Upload JPEG" />
        )}
      </div>

      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={floorEditSuccuss}
        autoHideDuration={5000}
        onClose={handleClose}
      >
        <div>
          <Alert onClose={handleClose} severity="success" variant="filled">
            Floor Edited successfully
          </Alert>
        </div>
      </Snackbar>
    </div>
  )
}

export default FloorDetails
