import { createContext, ReactNode, useCallback, useMemo, useState } from "react"
import { useMutation, useQueryClient } from "react-query"

import { NotificationUtils } from "@synapse-analytics/synapse-ui"
import { FormikProps, useFormik } from "formik"
import * as Yup from "yup"
import { shallow } from "zustand/shallow"

import { VisionAPI } from "../../../../API/VisionAPI"
import { useBranchesStore } from "../../../../store"
import {
  AvailableEntities,
  CamerasRegions,
  EntityRowData,
  IEntitiesSetup,
  RegionsObject,
  Subcategory,
} from "../../../../types/Custom/Interfaces"
import {
  getAvailableRegions,
  getEntityCamerasRegions,
  isCameraFulfilled,
  processRegions,
} from "../../../../utils/EntitiesSetupUtils"

export const EntitiesContext = createContext({} as IEntitiesSetup)

const validationFormik = () => {
  return Yup.object({
    type: Yup.string().required("Entity Type is required"),
    name: Yup.string().required("Entity name is required"),
  })
}

interface EntitiesContextProps {
  children: ReactNode
  editData?: EntityRowData
  activeTab?: AvailableEntities
  isEdit?: boolean
  setIsDialogOpen: (isOpen: boolean) => void
  isDialogOpen: boolean
  handleClose: () => void
}

const EntitiesContextProvider = ({
  children,
  editData,
  isEdit,
  activeTab,
  setIsDialogOpen,
  isDialogOpen,
  handleClose,
}: EntitiesContextProps) => {
  const queryClient = useQueryClient()

  const [selectedBranch] = useBranchesStore(
    (state: { selectedBranch: number | null }) => [state.selectedBranch],
    shallow
  )

  const formik: FormikProps<EntityRowData> = useFormik<EntityRowData>({
    initialValues: {
      id: editData?.id,
      name: editData?.name ?? "",
      type: isEdit ? editData?.type ?? "Entrance Gate" : activeTab ?? "Entrance Gate",
      category: editData?.category,
      subcategory: editData?.subcategory,
      area: editData?.area,
      branch: selectedBranch as number,
    },
    enableReinitialize: true,
    validationSchema: validationFormik,
    onSubmit: (values) => (isEdit ? editEntity({ values }) : addEntity({ values })),
  })

  const [selectedCameras, setSelectedCameras] = useState<number[]>(editData?.cameras ?? [])
  const [selectedDrawingCamera, setSelectedDrawingCamera] = useState<number | null>(selectedCameras[0] as number)
  const [allRegions, setAllRegions] = useState<CamerasRegions>(getEntityCamerasRegions(editData?.regions ?? []))
  const [activeStep, setActiveStep] = useState(0)
  const [subcategories, setSubcategories] = useState<Subcategory[]>([])
  const [isCancelMessageOpen, setIsCancelMessageOpen] = useState(false)
  const [selectedRegion, setSelectedRegion] = useState(0)
  const [selectedState, setSelectedState] = useState<0 | 1 | 2 | 8>(
    formik.values.type === "Dwelling Area" ? 2 : formik.values.type === "Cashier" ? 8 : 0
  )

  const handleAddEntity = async (values: EntityRowData) => {
    // Add logic for processing regions or other relevant data
    const regionsArray = processRegions(allRegions)

    // Handle adding entity based on type
    switch (values.type) {
      case "Entrance Gate":
        return VisionAPI.addGate({
          name: values.name as string,
          regions: regionsArray,
          branch: values.branch,
        })
      case "Cashier":
        return VisionAPI.addCashier({
          name: values.name as string,
          regions: regionsArray,
          branch: values.branch,
        })
      case "Tenant":
        return VisionAPI.addShop({
          name: values.name as string,
          area: values.area,
          category: parseInt(values.category as string),
          subcategory: parseInt(values.subcategory as string),
          regions: regionsArray,
          branch: values.branch,
        })
      case "Corridor":
        return VisionAPI.addCorridor({
          name: values.name as string,
          branch: values.branch,
          area: values.area,
          regions: regionsArray,
        })
      case "Car Parking":
        return VisionAPI.addCarParking({
          name: values.name as string,
          branch: values.branch,
          regions: regionsArray,
        })
      case "Dwelling Area":
        return VisionAPI.addDwellingArea({
          name: values.name as string,
          regions: regionsArray,
          category: parseInt(values.category as string),
          subcategory: parseInt(values.subcategory as string),
          branch: values.branch,
        })
    }
  }

  const handleSaveOnlyForm = () => {
    editEntity({ values: formik.values, shouldSaveOnlyForm: true })
  }

  const handleEditEntity = async (values: EntityRowData, shouldSaveOnlyForm?: boolean) => {
    // Add logic for processing regions or other relevant data
    const regionsArray = processRegions(allRegions)

    // Handle editing entity based on type
    switch (values.type) {
      case "Entrance Gate":
        return VisionAPI.updateGate(values.id as number, {
          name: values.name as string,
          branch: values.branch,
          regions: !shouldSaveOnlyForm ? regionsArray : undefined,
        })
      case "Cashier":
        return VisionAPI.updateCashier(values.id as number, {
          name: values.name as string,
          branch: values.branch,
          regions: !shouldSaveOnlyForm ? regionsArray : undefined,
        })
      case "Tenant":
        return VisionAPI.updateShop(values.id as number, {
          name: values.name as string,
          area: values.area,
          regions: !shouldSaveOnlyForm ? regionsArray : undefined,
          category: parseInt(values.category as string),
          subcategory: parseInt(values.subcategory as string),
          branch: values.branch,
        })
      case "Corridor":
        return VisionAPI.updateCorridor(values.id as number, {
          name: values.name as string,
          branch: values.branch,
          area: values.area,
          regions: !shouldSaveOnlyForm ? regionsArray : undefined,
        })
      case "Car Parking":
        return VisionAPI.updateCarParking(values.id as number, {
          name: values.name as string,
          branch: values.branch,
          regions: !shouldSaveOnlyForm ? regionsArray : undefined,
        })
      case "Dwelling Area":
        return VisionAPI.updateDwellingArea(values.id as number, {
          name: values.name as string,
          regions: !shouldSaveOnlyForm ? regionsArray : undefined,
          category: parseInt(values.category as string),
          subcategory: parseInt(values.subcategory as string),
          branch: values.branch,
        })
    }
  }

  const { mutate: addEntity, isLoading: addEntityLoading } = useMutation(
    (values: { values: EntityRowData }) => handleAddEntity(values.values),
    {
      onSuccess: () => {
        NotificationUtils.toast(`${formik.values.type} entity created successfully`, {
          severity: "success",
        })
        // Refetch entities & regions
        invalidateEntityQueries(formik.values.type)
        queryClient.invalidateQueries("fetchRegions")
        // Close dialog
        handleCloseDialog()
      },
    }
  )

  const { mutate: editEntity, isLoading: editEntityLoading } = useMutation(
    (values: { values: EntityRowData; shouldSaveOnlyForm?: boolean }) =>
      handleEditEntity(values.values, values.shouldSaveOnlyForm),
    {
      onSuccess: () => {
        NotificationUtils.toast(`${formik.values.type} entity updated successfully`, {
          severity: "success",
        })
        // Refetch entities & regions
        invalidateEntityQueries(formik.values.type)
        queryClient.invalidateQueries("fetchRegions")
        // Close dialog
        handleCloseDialog()
      },
    }
  )

  function invalidateEntityQueries(type: AvailableEntities) {
    switch (type) {
      case "Entrance Gate":
        queryClient.invalidateQueries("fetchEntranceEntities")
        break
      case "Cashier":
        queryClient.invalidateQueries("fetchCashierEntities")
        break
      case "Tenant":
        queryClient.invalidateQueries("fetchShopsEntities")
        break
      case "Corridor":
        queryClient.invalidateQueries("fetchCorridorEntities")
        break
      case "Car Parking":
        queryClient.invalidateQueries("fetchCarParkingEntities")
        break
      case "Dwelling Area":
        queryClient.invalidateQueries("fetchDwellingAreasEntities")
        break
    }
  }

  //after confirm to close , close dialog and reset states
  const handleCloseDialog = () => {
    handleClose()
    setActiveStep(0)
    formik.resetForm()
    setIsCancelMessageOpen(false)
    setSelectedCameras([])
    setSelectedDrawingCamera(null)
    setSelectedRegion(0)
    setSelectedState(0)
    setAllRegions({})
  }

  /**
   * A helper function to get the selected region object for a given state and region index.
   *
   * @function getRegions
   * @param {number} selectedState - The active state (step) for which the region object is being retrieved.
   * @param {number} selectedRegion - The index of the selected region to be retrieved.
   * @returns {RegionsObject[]} - Returns the region object for the selected camera and state.
   */
  const getRegions = useCallback(
    (selectedState: number): RegionsObject[] => {
      return allRegions[`${selectedDrawingCamera}`]?.[selectedState]?.regions
    },
    [allRegions, selectedDrawingCamera]
  )

  const handleStateChange = (state: number) => {
    setSelectedState(state as 0 | 1 | 2 | 8)
    // reset selected region on region type / step change
    setSelectedRegion(0)
  }

  const hasMinimumFulfillment = Object.keys(allRegions).some((cameraId) => {
    return isCameraFulfilled(formik.values.type, parseInt(cameraId), allRegions)
  })

  const isLoading = addEntityLoading || editEntityLoading
  const availableRegions = useMemo(() => getAvailableRegions(formik.values.type), [formik.values.type])

  return (
    <EntitiesContext.Provider
      value={{
        formik,
        hasMinimumFulfillment,
        isLoading,
        setActiveStep,
        activeStep,
        setAllRegions,
        allRegions,
        setSelectedRegion,
        selectedRegion,
        selectedState,
        setSelectedState,
        handleStateChange,
        setSelectedCameras,
        selectedCameras,
        setIsCancelMessageOpen,
        isCancelMessageOpen,
        handleCloseDialog,
        handleSaveOnlyForm,
        isEdit,
        setIsDialogOpen,
        isDialogOpen,
        setSubcategories,
        subcategories,
        setSelectedDrawingCamera,
        selectedDrawingCamera,
        availableRegions,
        getRegions,
      }}
    >
      {children}
    </EntitiesContext.Provider>
  )
}
export default EntitiesContextProvider
