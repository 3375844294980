import { AxiosResponse } from "axios"
import { OpenAPIV2 } from "openapi-types"

import { AxiosApi } from "../lib/axios"
import {
  PaginatedCamerasList,
  PaginatedFootage,
  PaginatedCampaignsList,
  PaginatedCarsLogs,
  PaginatedMissingData,
  PaginatedNotifications,
  PaginatedSecurityEvents,
  PaginatedSynopsis,
  PaginatedCVCLogs,
  CameraServicesFilterOptions,
} from "../types/Custom/Interfaces"
import { definitions } from "../types/Generated/apiTypes"
import { CustomTokenRefresh } from "../types/Generated/authentication/CustomTokenRefresh"

/**  <--- Access Tokens --->  */
type AccessToken = definitions["AuthToken"]
type AuthTokenCreate = definitions["AuthTokenCreate"]
type AuthTokenCreateResponse = definitions["AuthTokenCreateResponse"]
/**  <--- Active modules --->  */
type ActiveModules = definitions["ActiveModules"]

/**  <--- AV Chatbot --->  */
type ChatMessage = definitions["ChatMessage"]

/**  <--- Branches --->  */
type Branch = definitions["Branch"]
type BranchesAnalytics = definitions["BranchesAnalytics"]

/**  <--- Calendar --->  */
type Weather = definitions["Weather"]

/**  <--- Cameras --->  */
type ShortCamera = definitions["CameraList"]
type SingleCamera = definitions["CameraRetrieveUpdate"]
type CamerasHealthStatistics = definitions["CamerasHealthStatistics"]
type CameraHealth = definitions["CameraHealth"]
type Node = definitions["NodeUpdateRetrieve"]
type Stream = definitions["Stream"]
type CameraCreate = definitions["CameraCreate"]
type CameraFrame = definitions["CameraFrame"]

/**  <--- Campaigns --->  */
type CampaignDetail = definitions["CampaignDetail"]
type CampaignCreate = definitions["CampaignCreate"]
type CampaignNotesCreate = definitions["CampaignNotesCreate"]

/**  <--- Cars --->  */
type CarCounterLogs = definitions["CarCounterLogs"]
type CarCountsByGate = definitions["CarCountsByGate"]
type WeekDaysAverageCount = definitions["WeekDaysAverageCount"]
type CarListMembershipLicensePlate = definitions["CarListMembershipLicensePlate"]
type NewReturning = definitions["NewReturning"]
type CarVisit = definitions["CarVisit"]
type Parking = definitions["Parking"]
type TotalTimeSpent = definitions["TotalTimeSpent"]
type ListOfInterest = definitions["ListOfInterest"]

/**  <--- Cashiers --->  */
type Cashier = definitions["Cashier"]
type CashierPerformanceAnalytics = definitions["CashierPerformanceAnalytics"]
type CashierConversionRate = definitions["CashierConversionRate"]

/**  <--- Corridors --->  */

type Corridor = definitions["Corridor"]
type CorridorAgeGenderCount = definitions["CorridorAgeGenderCount"]
type CorridorDailyCount = definitions["CorridorDailyCount"]
type CorridorCameraCount = definitions["CorridorCameraCount"]

/**  <--- Categories --->  */
type Category = definitions["Category"]

/**  <--- Counter insights --->  */
type CounterInsights = definitions["CounterInsights"]

/**  <--- Dwelling areas --->  */
type DwellingArea = definitions["DwellingArea"]
type DwellingAreaAnalytics = definitions["DwellingAreaAnalytics"]
type DwellingAreaDailyCounts = definitions["DwellingAreaDailyCounts"]
type DwellingAreaHourlyAVGCounts = definitions["DwellingAreaHourlyAVGCounts"]
type DwellingAreaWeekDaysCounts = definitions["DwellingAreaWeekDaysCounts"]

/**  <--- Entity groups --->  */
type EntityGroupsCounts = definitions["EntityGroupsCounts"]

/**  <--- Floor plans --->  */
type FloorPlanList = definitions["FloorPlanList"]
type FloorPlanCreate = definitions["FloorPlanCreate"]
type FloorPlanVersionList = definitions["FloorPlanVersionList"]
type CameraFloorMapping = definitions["CameraFloorMapping"]
type FloorPlanVersionCreate = definitions["FloorPlanVersionCreate"]
type CameraOnFloor = definitions["CameraOnFloor"]
type CameraFloorCoordinate = definitions["CameraFloorCoordinate"]
type FloorPlanUpdateRetrieve = definitions["FloorPlanUpdateRetrieve"]

/**  <--- Footages --->  */
type CameraFootageRetrieve = definitions["CameraFootageRetrieve"]

/**  <--- Gates --->  */
type Gate = definitions["Gate"]

/**  <--- Heatmap --->  */
type HeatMapHourly = definitions["HeatMapHourly"]
type HeatmapLogMinMax = definitions["HeatmapLogMinMax"]

/**  <--- MCP --->  */
type CameraMCPLogs = definitions["CameraMCPLogs"]
type FloorVersionMCPLogs = definitions["FloorVersionMCPLogs"]

/**  <--- Missing Data --->  */
type MissingCounterLog = definitions["MissingCounterLog"]
type MissingDataInfo = definitions["MissingDataInfo"]
type MissingSliceCounterLog = definitions["MissingSliceCounterLog"]
type MissingDataLogs = definitions["MissingDataLogs"]

/**  <--- Nodes --->  */
type NodeUpdateRetrieve = definitions["NodeUpdateRetrieve"]
type NodeDownTimeAnalytics = definitions["NodeDownTimeAnalytics"]

/**  <--- Notifications --->  */
type NotificationCount = definitions["NotificationCount"]

/**  <--- Permissions --->  */
type Permission = definitions["Permission"]

/**  <--- Regions --->  */
type Region = definitions["counterentitiy_region_serializer"]

/**  <--- Security events --->  */
type SecurityViolationAnalytics = definitions["SecurityViolationAnalytics"]

/**  <--- Shops--->  */
type Shop = definitions["Shop"]
type ShopsAnalytics = definitions["ShopsAnalytics"]
type CategoryCount = definitions["CategoryCount"]
type HourlyAVGCounts = definitions["HourlyAVGCounts"]
type ShopsCategoryLogs = definitions["ShopsCategoryLogs"]

/**  <--- Support email--->  */
type SupportEmailRequest = definitions["SupportEmailRequest"]

/**  <--- System --->  */
type RestartRequest = definitions["RestartRequest"]
type Restart = definitions["Restart"]
type RestartAll = definitions["RestartAll"]
type Module = definitions["Module"]

/**  <--- User --->  */
type PasswordChange = definitions["PasswordChange"]
type CustomPasswordReset = definitions["CustomPasswordReset"]
type PasswordResetConfirm = definitions["PasswordResetConfirm"]

/**  <--- Users --->  */
type UserList = definitions["UserList"]
type UserCreateUpdate = definitions["UserCreateUpdate"]
type UserRetrieve = definitions["UserRetrieve"]

/**  <--- General Types --->  */
type EntitiesLogs = definitions["EntitiesLogs"]
type LogsHistory = definitions["LogsHistory"]
type DwellingLogs = definitions["DwellingLogs"]
type Tag = definitions["Tag"]
type ServiceLogsExist = definitions["ServiceLogsExist"]

// TODO:: Separate each module into a single API file that contains module's specific types and functions
// TODO:: Extract post requests params objects' types into custom types file for type safety and avoid repetitions
class VisionAPI extends AxiosApi {
  /**  <--- Swagger Schema --->  */

  fetchSwaggerDocs = async (): Promise<OpenAPIV2.Document> => {
    const { data } = await this.fetchResource(`/api/docs/swagger.json/`)
    return data
  }

  refreshToken = (refresh: string): Promise<AxiosResponse<CustomTokenRefresh>> => {
    return this.postResource("/api/token/refresh/", { refresh })
  }

  /**  <--- Active modules --->  */

  fetchActiveModules = async (): Promise<ActiveModules> => {
    const { data } = await this.fetchResource("/api/active-modules/")
    return data
  }

  /**  <--- User Auth Tokens --->  */

  fetchAccessTokensList = async (): Promise<AccessToken[]> => {
    const { data } = await this.fetchResource("/api/user-auth-token")
    return data
  }

  generateAccessToken = async (values: AuthTokenCreate): Promise<AuthTokenCreateResponse> => {
    const { data } = await this.postResource(`/api/user-auth-token`, values)
    return data
  }

  deleteAccessToken = async (id?: number): Promise<AxiosResponse> => {
    const { data } = await this.deleteResource(`/api/user-auth-token/${id}`)
    return data
  }

  /**  <--- AV Chatbot --->  */

  sendMessageToChatbot = async (values: ChatMessage): Promise<string> => {
    const { data } = await this.postResource(`/api/av-chatbot/chat/`, values)
    return data
  }

  /**  <--- Branches --->  */

  fetchBranches = async (): Promise<Branch[]> => {
    const { data } = await this.fetchResource("/api/branches/")

    return data
  }

  fetchBranchesAnalytics = async (params: {
    from_date: string
    to_date: string
    date_slice: string
    branch_ids?: string
    include_staff?: boolean
  }): Promise<BranchesAnalytics[]> => {
    const { data } = await this.fetchResource("/api/branches/analytics", params)

    return data
  }

  fetchUserBranches = async ({ user_id }: { user_id: string }): Promise<Branch[]> => {
    const { data } = await this.fetchResource("/api/user-branches/", {
      user_id,
    })

    return data
  }

  /**  <--- Calendar --->  */

  fetchWeatherCodes = async (params: { from_date: string; to_date: string }): Promise<Weather[]> => {
    const { data } = await this.fetchResource("/api/calendar/weather/", params)
    return data
  }
  /**  <--- Cameras --->  */

  fetchCamerasShortList = async (params: {
    branch: number
    services?: string
    active?: boolean
    placed?: boolean
    services_filter_type?: string
    entity?: string
  }): Promise<ShortCamera[]> => {
    const { data } = await this.fetchResource("/api/cameras/short-list/", {
      ...params,
    })

    return data
  }

  fetchCamerasPaginated = async (params: {
    branch: number
    services?: CameraServicesFilterOptions
    active?: boolean
    placed?: boolean
    services_filter_type?: string
    entity?: string
    search?: string
    ordering?: string
    floor_version?: string
    mapped?: boolean
    node?: string
    ids?: string
    page?: number
    limit?: number
  }): Promise<PaginatedCamerasList> => {
    let servicesStr = ""
    for (const key in params.services) {
      if (params.services[key as keyof CameraServicesFilterOptions] === true) {
        servicesStr += `${key},`
      }
    }
    servicesStr = servicesStr.slice(0, -1)

    const { data } = await this.fetchResource("/api/cameras/", { ...params, services: servicesStr })

    return data
  }

  fetchSingleCamera = async (id: number): Promise<SingleCamera> => {
    const { data } = await this.fetchResource(`/api/cameras/${id}/`)

    return data
  }

  fetchCameraFrame = async ({ url }: { url?: string }): Promise<CameraFrame> => {
    const { data } = await this.fetchResource(`/api/cameras/sample-frame/${url}/`)
    return data
  }

  fetchCamerasStream = async ({
    cameras,
    mode,
    service,
  }: {
    cameras: string
    mode: string
    service?: string
  }): Promise<Stream> => {
    const { data } = await this.fetchResource(`/api/streams/?${cameras}`, { mode, service })
    return data
  }

  fetchCamerasHealthStats = async ({ branch }: { branch: number }): Promise<CamerasHealthStatistics> => {
    const { data } = await this.fetchResource("/api/cameras/health-stats/", { branch })

    return data
  }

  fetchCameraHealth = async (id: number): Promise<CameraHealth> => {
    const { data } = await this.fetchResource(`/api/cameras/${id}/health/`)

    return data
  }

  fetchCameraDownTime = async ({ id, start_date, end_date }: { id: number; start_date: string; end_date: string }) => {
    const { data } = await this.fetchResource(`/api/cameras/${id}/down-time`, {
      start_date,
      end_date,
    })

    return data
  }

  fetchCameraLogs = async (id: number): Promise<LogsHistory[]> => {
    const { data } = await this.fetchResource(`/api/cameras/${id}/history`)
    return data
  }

  fetchCameraTags = async (): Promise<Tag[]> => {
    const { data } = await this.fetchResource(`/api/cameras/tags/`)
    return data
  }

  refreshSampleFrame = async ({ id }: { id?: number }): Promise<AxiosResponse> => {
    const { data } = await this.patchResource(`/api/cameras/${id}/sample-frame/refresh/`)
    return data
  }

  fetchCameraViewChangeLogs = async (params: {
    search?: string
    resolved?: boolean
    branch: number
    page: number
    limit?: number
  }): Promise<PaginatedCVCLogs> => {
    const { data } = await this.fetchResource(`/api/cameras/view-changes/`, params)
    return data
  }

  resolveCameraViewChange = async (id: number): Promise<AxiosResponse> => {
    return this.patchResource(`/api/cameras/view-changes/${id}/resolve`)
  }

  updateCameraStatus = async ({ id, status }: { id?: number; status: boolean }): Promise<SingleCamera> => {
    const { data } = await this.patchResource(`/api/cameras/${id}/`, { active: status })
    return data
  }

  createCamera = async (cameraValues: CameraCreate): Promise<CameraCreate> => {
    const { data } = await this.postResource("/api/cameras/", { ...cameraValues })
    return data
  }

  editCamera = async (cameraValues: CameraCreate, id?: number): Promise<CameraCreate> => {
    const { data } = await this.patchResource(`/api/cameras/${id}/`, { ...cameraValues })
    return data
  }

  deleteCamera = async ({ id }: { id?: number }): Promise<AxiosResponse> => {
    const { data } = await this.deleteResource(`/api/cameras/${id}/`)
    return data
  }

  deleteMultipleCameras = async ({ cameras }: { cameras: number[] }): Promise<AxiosResponse> => {
    const { data } = await this.deleteResource(`/api/cameras/bulk_delete/`, {
      data: { cameras: cameras },
    })
    return data
  }

  /**  <--- Campaigns --->  */

  fetchPaginatedCampaigns = async ({
    limit = 5,
    ordering = "=-created_at,-id",
    ...params
  }: {
    page: number
    search?: string
    limit?: number
    start_date?: string
    end_date?: string
    status?: string
    ordering?: string
  }): Promise<PaginatedCampaignsList> => {
    const { data } = await this.fetchResource(`/api/campaigns/`, { limit, ordering, ...params })

    return data
  }

  fetchSingleCampaign = async ({ id }: { id: string | number }): Promise<CampaignDetail> => {
    const { data } = await this.fetchResource(`/api/campaigns/${id}/`)
    return data
  }

  fetchCampaignTags = async (): Promise<Tag[]> => {
    const { data } = await this.fetchResource(`/api/campaigns/tags`)
    return data
  }

  // Create campaign
  createCampaign = async (values: CampaignCreate): Promise<CampaignCreate> => {
    const { data } = await this.postResource("/api/campaigns/", values)
    return data
  }

  // Edit campaign
  editCampaign = async (id: number, values: CampaignCreate): Promise<CampaignCreate> => {
    const { data } = await this.patchResource(`/api/campaigns/${id}/`, values)
    return data
  }

  // add note to campaign
  addCampaignNote = async (values: CampaignNotesCreate): Promise<AxiosResponse<CampaignNotesCreate>> => {
    const { data } = await this.postResource("/api/campaigns/notes/", values)
    return data
  }

  // Delete campaign
  deleteCampaign = async (id?: number): Promise<AxiosResponse> => {
    const { data } = await this.deleteResource(`/api/campaigns/${id}/`)
    return data
  }

  // delete campaign note
  deleteNoteCampaign = async (id?: number): Promise<AxiosResponse> => {
    const { data } = await this.deleteResource(`/api/campaigns/notes/${id}`)
    return data
  }

  /**  <--- Cars --->  */

  fetchCarParkingCounterStats = async (params: {
    from_date: string
    to_date: string
    car_type: string
    list_of_interest: number
    brand: string
    date_slice: string
    entity_ids?: number
  }): Promise<CarCounterLogs[]> => {
    const { data } = await this.fetchResource("/api/cars/counter-logs/", {
      ...params,
    })

    return data
  }

  fetchCarsGateCounts = async (params: {
    start_dt: string
    end_dt: string
    car_type: string
    brand: string
    list_of_interest: number
  }): Promise<CarCountsByGate[]> => {
    const { data } = await this.fetchResource("/api/cars/counts-by-gate/", { ...params })
    return data
  }

  fetchCarsDaysAverageCounts = async (params: {
    from_date: string
    to_date: string
    car_type: string
    list_of_interest: number
    brand: string
  }): Promise<WeekDaysAverageCount[]> => {
    const { data } = await this.fetchResource("/api/cars/day-avg-count/", { ...params })
    return data
  }

  fetchCarLicensePlatesFromLists = async ({
    list_of_interest,
  }: {
    list_of_interest: number
  }): Promise<CarListMembershipLicensePlate[]> => {
    const { data } = await this.fetchResource(`/api/cars/lists-of-interest/${list_of_interest}/license-plate/`)
    return data
  }

  addCarLicensePlateToList = async ({
    list_of_interest,
    license_plate,
  }: {
    list_of_interest: number
    license_plate: string
  }): Promise<AxiosResponse> => {
    return this.postResource(`/api/cars/lists-of-interest/${list_of_interest}/license-plate/${license_plate}/`)
  }

  deleteCarLicensePlateFromList = async ({
    list_of_interest,
    license_plate,
  }: {
    list_of_interest: number
    license_plate: string
  }): Promise<AxiosResponse> => {
    const { data } = await this.deleteResource(
      `/api/cars/lists-of-interest/${list_of_interest}/license-plate/${license_plate}/`
    )
    return data
  }

  deleteCarListsOfInterest = async (id: number): Promise<AxiosResponse> => {
    const { data } = await this.deleteResource(`/api/lists-of-interest/${id}/`)
    return data
  }

  fetchCarListsOfInterest = async (params?: {
    search?: string
    type?: string
    page?: number
    page_size?: number
  }): Promise<ListOfInterest[]> => {
    const { data } = await this.fetchResource(`/api/lists-of-interest/`, { ...params })
    return data.results
  }

  addCarListOfInterest = async (values: ListOfInterest): Promise<ListOfInterest> => {
    const { data } = await this.postResource("/api/lists-of-interest/", {
      ...values,
    })
    return data
  }

  fetchCarsTotalCounts = async (params: {
    start_dt: string
    end_dt: string
    car_type: string
    brand: string
    list_of_interest: number
  }): Promise<NewReturning> => {
    const { data } = await this.fetchResource("/api/cars/logs/counts/new-returning/", { ...params })
    return data
  }

  fetchCarLogsList = async (params: {
    start_dt: string
    end_dt: string
    car_type?: string
    brand?: string
    list_of_interest?: number
    page: number
    search?: string
    limit?: number
    entity?: string
    camera?: string
    state?: string
    car_color?: string
  }): Promise<PaginatedCarsLogs> => {
    const { data } = await this.fetchResource("/api/cars/logs/list/", { ...params })
    return data
  }

  fetchCarLicensePlate = async ({ license_plate }: { license_plate: string }): Promise<CarVisit> => {
    const { data } = await this.fetchResource(`/api/cars/logs/${license_plate}/visits/`)
    return data
  }

  fetchCarParking = async (params: { camera?: number; ordering?: string } = {}): Promise<Parking[]> => {
    const { data } = await this.fetchResource("/api/cars/parking/", params)
    return data
  }

  addCarParking = async (values: Parking): Promise<Parking> => {
    const { data } = await this.postResource("/api/cars/parking/", values)
    return data
  }

  updateCarParking = async (id: number, values: Parking): Promise<Parking> => {
    const { data } = await this.patchResource(`/api/cars/parking/${id}/`, values)
    return data
  }

  fetchCarParkingEntity = async ({ entity }: { entity: string }): Promise<Parking> => {
    const { data } = await this.fetchResource(`/api/cars/parking/${entity}/`)
    return data
  }

  deleteCarParking = async (entity: number): Promise<AxiosResponse> => {
    const { data } = await this.deleteResource(`/api/cars/parking/${entity}/`)
    return data
  }

  fetchCarParkingRegions = async ({ camera, entity }: { camera?: number; entity?: number }): Promise<Region[]> => {
    const { data } = await this.fetchResource("/api/cars/regions/", {
      camera,
      entity,
    })
    return data
  }

  createCarParkingRegion = async (values: Region): Promise<Region> => {
    const { data } = await this.postResource("/api/cars/regions/", values)
    return data
  }

  fetchCarParkingRegion = async (id: number): Promise<Region> => {
    const { data } = await this.fetchResource("/api/cars/regions/", {
      id,
    })
    return data
  }

  deleteCarParkingRegion = async (id: number): Promise<AxiosResponse> => {
    const { data } = await this.deleteResource(`/api/cars/regions/${id}/`)
    return data
  }

  fetchCarsTimeSpentInside = async (params: {
    from_date: string
    to_date: string
    car_type: string
    list_of_interest: number
    brand: string
  }): Promise<TotalTimeSpent> => {
    const { data } = await this.fetchResource("/api/cars/time-spent/", { ...params })
    return data
  }
  /**  <--- Cashiers --->  */

  fetchCashiers = async (params: { branch: number; camera?: number; ordering?: string }): Promise<Cashier[]> => {
    const { data } = await this.fetchResource(`/api/cashiers/`, params)
    return data
  }

  fetchCashier = async ({ id }: { id: number }): Promise<Cashier> => {
    const { data } = await this.fetchResource(`/api/cashiers/${id}/`)
    return data
  }

  addCashier = async (values: Cashier): Promise<Cashier> => {
    const { data } = await this.postResource("/api/cashiers/", values)
    return data
  }

  updateCashier = async (id: number, values: Cashier): Promise<Cashier> => {
    const { data } = await this.patchResource(`/api/cashiers/${id}/`, values)
    return data
  }

  deleteCashier = async (entity: number): Promise<AxiosResponse> => {
    const { data } = await this.deleteResource(`/api/cashiers/${entity}/`)
    return data
  }

  fetchCashiersAnalytics = async (params: {
    from_date: string
    to_date: string
    branch_id: number
  }): Promise<CashierPerformanceAnalytics[]> => {
    const { data } = await this.fetchResource(`/api/cashiers/analytics`, params)
    return data
  }

  fetchCashiersHourlyConversionRate = async (params: {
    from_date: string
    to_date: string
    branch_id: number
  }): Promise<CashierConversionRate> => {
    const { data } = await this.fetchResource(`/api/cashiers/hourly-conversion-rate`, params)
    return data
  }

  /**  <--- Corridors --->  */

  fetchCorridors = async (params: { branch: number; camera?: number; ordering?: string }): Promise<Corridor[]> => {
    const { data } = await this.fetchResource(`/api/corridors/`, params)
    return data
  }

  addCorridor = async (values: Corridor): Promise<Corridor> => {
    const { data } = await this.postResource("/api/corridors/", values)
    return data
  }

  fetchCorridorsCounts = async (params: {
    from_date: string
    to_date: string
    branch: number
    include_staff?: boolean
  }): Promise<CorridorAgeGenderCount[]> => {
    const { data } = await this.fetchResource("/api/corridors/count", { ...params })
    return data
  }

  fetchCorridorsCounterStats = async (params: {
    from_date: string
    to_date: string
    entity_ids?: number
    date_slice: string
    include_staff?: boolean
    branch: number
    week_day?: number
  }): Promise<EntitiesLogs> => {
    const { data } = await this.fetchResource("/api/corridors/counter-logs", {
      ...params,
    })

    return data
  }

  fetchCorridorsDailyCounts = async (params: {
    from_date: string
    to_date: string
    branch: number
    include_staff?: boolean
  }): Promise<CorridorDailyCount[]> => {
    const { data } = await this.fetchResource("/api/corridors/daily-count", {
      ...params,
    })

    return data
  }

  fetchCorridorEntity = async ({ entity }: { entity: string }): Promise<Corridor> => {
    const { data } = await this.fetchResource(`/api/corridors/${entity}/`)
    return data
  }

  updateCorridor = async (id: number, values: Corridor): Promise<Corridor> => {
    const { data } = await this.patchResource(`/api/corridors/${id}/`, values)
    return data
  }

  deleteCorridor = async (entity: number): Promise<AxiosResponse> => {
    const { data } = await this.deleteResource(`/api/corridors/${entity}/`)
    return data
  }

  fetchCorridorsCameraBreakdownStats = async (
    id: number,
    params: {
      from_date: string
      to_date: string
      branch: number
      include_staff?: boolean
    }
  ): Promise<CorridorCameraCount[]> => {
    const { data } = await this.fetchResource(`/api/corridors/${id}/cameras-count`, {
      ...params,
    })
    return data
  }

  /**  <--- Categories --->  */

  fetchCategories = async (): Promise<Category[]> => {
    const { data } = await this.fetchResource("/api/categories/")
    return data
  }

  fetchCategory = async (id: number): Promise<Category> => {
    const { data } = await this.fetchResource(`/api/categories/${id}/`)
    return data
  }

  createCategory = async (values: Category): Promise<Category> => {
    const { data } = await this.postResource("/api/categories/", values)
    return data
  }

  updateCategory = async (id: number, values: Category): Promise<Category> => {
    const { data } = await this.patchResource(`/api/categories/${id}/`, values)
    return data
  }

  deleteCategory = async (id: number): Promise<AxiosResponse> => {
    const { data } = await this.deleteResource(`/api/categories/${id}/`)
    return data
  }

  /**  <--- Counter Insights --->  */

  fetchCounterInsights = async (params: {
    from_dt: string
    to_dt: string
    branch: number
  }): Promise<CounterInsights[]> => {
    const { data } = await this.fetchResource("/api/counter/insights", { ...params })
    return data
  }

  /**  <--- Dwelling Areas --->  */

  fetchDwellingAreas = async (params: {
    branch: number
    camera: number
    ordering?: string
  }): Promise<DwellingArea[]> => {
    const { data } = await this.fetchResource("/api/dwelling-areas/", params)
    return data
  }

  addDwellingArea = async (values: DwellingArea): Promise<DwellingArea> => {
    const { data } = await this.postResource("/api/dwelling-areas/", values)
    return data
  }

  fetchDwellingAreasAnalytics = async ({
    from_date,
    to_date,
    branch,
    include_staff,
    entity,
    ordering = "-total_dwelling_count",
  }: {
    from_date: string
    to_date: string
    branch: number
    include_staff?: boolean
    entity?: number
    ordering?: string
  }): Promise<DwellingAreaAnalytics[]> => {
    const { data } = await this.fetchResource("/api/dwelling-areas/analytics", {
      from_date,
      to_date,
      branch,
      include_staff,
      entity,
      ordering,
    })
    return data
  }

  fetchDwellingAreasCounterStats = async (params: {
    from_date: string
    to_date: string
    include_staff?: boolean
    entity_ids?: number
    live?: boolean
    ordering?: string
    date_slice: string
  }): Promise<DwellingLogs[]> => {
    const { data } = await this.fetchResource("/api/dwelling-areas/dwelling-logs", { ...params })
    return data
  }

  fetchDwellingAreaDailyCounts = async ({
    id,
    from_date,
    to_date,
    include_staff,
  }: {
    id: number
    from_date: string
    to_date: string
    include_staff?: boolean
  }): Promise<DwellingAreaDailyCounts[]> => {
    const { data } = await this.fetchResource(`/api/dwelling-areas/${id}/daily-counts`, {
      from_date,
      to_date,
      include_staff,
    })
    return data
  }

  fetchDwellingAreaHourlyAvgCounts = async ({
    id,
    from_date,
    to_date,
    include_staff,
  }: {
    id: number
    from_date: string
    to_date: string
    include_staff?: boolean
  }): Promise<DwellingAreaHourlyAVGCounts[]> => {
    const { data } = await this.fetchResource(`/api/dwelling-areas/${id}/hourly-avg-counts`, {
      from_date,
      to_date,
      include_staff,
    })
    return data
  }

  fetchDwellingWeekDaysCounts = async ({
    id,
    from_date,
    to_date,
    include_staff,
  }: {
    id: number
    from_date: string
    to_date: string
    include_staff?: boolean
  }): Promise<DwellingAreaWeekDaysCounts[]> => {
    const { data } = await this.fetchResource(`/api/dwelling-areas/${id}/week-days-counts`, {
      from_date,
      to_date,
      include_staff,
    })

    return data
  }

  fetchDwellingArea = async (id: number): Promise<DwellingArea> => {
    const { data } = await this.fetchResource(`/api/dwelling-areas/${id}/`)
    return data
  }

  updateDwellingArea = async (id: number, values: DwellingArea): Promise<DwellingArea> => {
    const { data } = await this.patchResource(`/api/dwelling-areas/${id}/`, values)
    return data
  }

  deleteDwellingArea = async (entity: number): Promise<AxiosResponse> => {
    const { data } = await this.deleteResource(`/api/dwelling-areas/${entity}/`)
    return data
  }

  /**  <--- Entity groups --->  */

  fetchEntityGroupsCounts = async (
    entity_id: number,
    params: {
      from_date: string
      to_date: string
      state: "IN" | "DWELL"
    }
  ): Promise<EntityGroupsCounts> => {
    const { data } = await this.fetchResource(`/api/entity/${entity_id}/groups-analytics`, { ...params })
    return data
  }

  /**  <--- Floors --->  */

  fetchFloorPlans = async ({ branch }: { branch: number }): Promise<FloorPlanList[]> => {
    const { data } = await this.fetchResource("/api/floor-plans/", { branch })
    return data
  }

  addFloorPlan = async (values: FormData): Promise<FloorPlanCreate> => {
    const { data } = await this.postResource("/api/floor-plans/", values)
    return data
  }

  editFloorPlan = async (
    id: number,
    { branch, ...values }: FloorPlanUpdateRetrieve
  ): Promise<FloorPlanUpdateRetrieve> => {
    const { data } = await this.patchResource(`/api/floor-plans/${id}/?branch=${branch}`, { ...values })
    return data
  }

  deleteFloorPlan = async (id: number, branch: number): Promise<AxiosResponse> => {
    const { data } = await this.deleteResource(`/api/floor-plans/${id}/?branch=${branch}`)
    return data
  }

  fetchFloorPlan = async (id: number): Promise<FloorPlanVersionList> => {
    const { data } = await this.fetchResource(`/api/floor-plans/floor-versions/${id}/`)
    return data
  }

  fetchCameraMappingCoors = async ({
    version_id,
    camera_id,
  }: {
    version_id: number
    camera_id: number
  }): Promise<CameraFloorMapping[]> => {
    const { data } = await this.fetchResource(`/api/floor-plans/floor-versions/${version_id}/mapping/${camera_id}/`)
    return data
  }

  addUpdateCameraMappingCoors = async ({
    version_id,
    camera_id,
    values,
  }: {
    version_id: number
    camera_id: number
    values: CameraFloorMapping[]
  }): Promise<CameraFloorMapping> => {
    const { data } = await this.putResource(
      `/api/floor-plans/floor-versions/${version_id}/mapping/${camera_id}/`,
      values
    )
    return data
  }

  deleteCameraMappingCoors = async ({
    version_id,
    camera_id,
  }: {
    version_id: number
    camera_id: number
  }): Promise<AxiosResponse> => {
    const { data } = await this.deleteResource(`/api/floor-plans/floor-versions/${version_id}/mapping/${camera_id}/`)
    return data
  }

  fetchFloorPlanAllVersions = async (
    floor_id: number,
    params?: { from_dt?: string; to_dt?: string }
  ): Promise<FloorPlanVersionList[]> => {
    const { data } = await this.fetchResource(`/api/floor-plans/${floor_id}/versions/`, params)
    return data
  }

  fetchFloorTags = async (): Promise<Tag[]> => {
    const { data } = await this.fetchResource(`/api/floor-plans/tags/`)
    return data
  }

  addFloorVersion = async (formData: FormData): Promise<FloorPlanVersionCreate> => {
    const { data } = await this.postResource(`/api/floor-plans/versions/`, formData)
    return data
  }

  fetchFloorPlanVersions = async ({
    floor_id,
    from_dt,
    to_dt,
  }: {
    floor_id: number
    from_dt?: string
    to_dt?: string
  }): Promise<FloorPlanVersionList[]> => {
    const { data } = await this.fetchResource(`/api/floor-plans/${floor_id}/versions/`, { from_dt, to_dt })
    return data
  }

  fetchCameraLocatingCoors = async ({
    floor_id,
    version_id,
  }: {
    floor_id: number
    version_id: number
  }): Promise<CameraOnFloor[]> => {
    const { data } = await this.fetchResource(`/api/floor-plans/${floor_id}/versions/${version_id}/cameras-locations/`)
    return data
  }

  addUpdateCameraLocatingCoors = async ({
    floor_id,
    version_id,
    values,
  }: {
    floor_id: number
    version_id: number
    values: CameraFloorCoordinate[]
  }): Promise<CameraFloorCoordinate> => {
    const { data } = await this.putResource(`/api/floor-plans/${floor_id}/versions/${version_id}/cameras-locations/`, [
      ...values,
    ])
    return data
  }

  deleteCameraLocatingCoors = async ({
    floor_id,
    version_id,
    camera_id,
  }: {
    floor_id: number
    version_id: number
    camera_id: number
  }): Promise<AxiosResponse> => {
    const { data } = await this.deleteResource(
      `/api/floor-plans/${floor_id}/versions/${version_id}/cameras-locations/${camera_id}/`
    )
    return data
  }

  fetchFloorPlansHistory = async (id: number): Promise<LogsHistory> => {
    const { data } = await this.fetchResource(`/api/floor-plans/${id}/history/`)
    return data
  }

  /**  <--- Camera Footages --->  */

  fetchCameraFootages = async ({
    camera,
    page = 1,
    limit,
    ordering = "-created_at",
  }: {
    camera: number
    page: number
    limit: number
    ordering?: string
  }): Promise<PaginatedFootage> => {
    const { data } = await this.fetchResource("/api/footage/", { camera, page, limit, ordering })
    return data
  }

  fetchFootage = async (id: number): Promise<CameraFootageRetrieve> => {
    const { data } = await this.fetchResource(`/api/footage/${id}`)
    return data
  }

  deleteFootage = async (id: number): Promise<AxiosResponse> => {
    const { data } = await this.deleteResource(`/api/cameras/${id}/`)
    return data
  }

  /**  <--- Gates --->  */

  fetchEntrances = async (params: { branch: number; camera?: number; ordering?: string }): Promise<Gate[]> => {
    const { data } = await this.fetchResource(`/api/gates/`, params)
    return data
  }

  addGate = async (values: Gate): Promise<Gate> => {
    const { data } = await this.postResource("/api/gates/", values)
    return data
  }

  updateGate = async (id: number, values: Gate): Promise<Gate> => {
    const { data } = await this.patchResource(`/api/gates/${id}/`, values)
    return data
  }

  fetchGatesCounterStats = async (params: {
    from_date: string
    to_date: string
    branch: number
    date_slice: string
    include_staff?: boolean
    entity_ids?: number
    week_day?: number
  }): Promise<EntitiesLogs> => {
    const { data } = await this.fetchResource("/api/gates/counter-logs", params)
    return data
  }

  exportGatesReport = async (params: { from_date: string; to_date: string }): Promise<File> => {
    const { data } = await this.fetchResource("/api/gates/report", params)
    return data
  }

  fetchGate = async (id: number): Promise<Gate> => {
    const { data } = await this.fetchResource(`/api/gates/${id}/`)
    return data
  }

  deleteGate = async (id: number) => {
    const { data } = await this.deleteResource(`/api/gates/${id}/`)
    return data
  }

  /**  <--- Heatmap --->  */

  fetchCameraHeatmapLogs = async ({
    camera_id,
    from_date,
    to_date,
    grid_width,
  }: {
    camera_id: number
    from_date: string
    to_date: string
    grid_width?: number
  }): Promise<HeatMapHourly[]> => {
    const { data } = await this.fetchResource(`/api/heatmap/cameras/${camera_id}/`, {
      from_date,
      to_date,
      grid_width,
    })
    return data
  }

  fetchHeatmapVersionLogs = async ({
    floor_version_id,
    from_date,
    to_date,
    grid_width,
  }: {
    floor_version_id: number
    from_date: string
    to_date: string
    grid_width?: number
  }): Promise<HeatMapHourly[]> => {
    const { data } = await this.fetchResource(`/api/heatmap/floor-versions/${floor_version_id}/`, {
      from_date,
      to_date,
      grid_width,
    })
    return data
  }

  fetchHeatmapTimeRange = async (): Promise<HeatmapLogMinMax> => {
    const { data } = await this.fetchResource("/api/heatmap/time-range/")
    return data
  }

  /**  <--- MCP --->  */

  fetchFloorMCPLogs = async ({
    floor_plan_id,
    date,
  }: {
    floor_plan_id: number
    date: string
  }): Promise<FloorVersionMCPLogs[]> => {
    const { data } = await this.fetchResource(`/api/mcp/floor-plans/${floor_plan_id}/logs/`, { date })
    return data
  }

  fetchCameraMCPLogs = async ({ camera_id, date }: { camera_id: number; date: string }): Promise<CameraMCPLogs[]> => {
    const { data } = await this.fetchResource(`/api/mcp/cameras/${camera_id}/logs/`, { date })
    return data
  }

  /**  <--- Missing Data --->  */

  // fetch missing data list for a time range
  fetchMissingData = async (params: {
    search?: string
    from_date: string
    to_date: string
    branch: number
    page?: number
    limit?: number
  }): Promise<PaginatedMissingData> => {
    const { data } = await this.fetchResource("/api/missing-data/info/", params)
    return data
  }

  // get logs estimation for a specific entity for a time range
  fetchLogsEstimation = async (params: {
    info_id: number
    entity_id: number
    start_date: string
    end_date: string
    months_for_average?: number
  }): Promise<MissingCounterLog[]> => {
    const { data } = await this.fetchResource("/api/missing-data/log-estimation/", params)
    return data
  }

  // fetch missing data analytics counter stats
  fetchMissingCounterLogs = async (params: {
    from_date: string
    to_date: string
    date_slice: string
    entities_type?: "shop" | "gate" | "corridor"
    branch: number
    entities?: string[]
  }): Promise<MissingSliceCounterLog[]> => {
    const { data } = await this.fetchResource("/api/missing-data/missing-counter-logs/", params)
    return data
  }

  // fetch missing data logs for an entity in a file
  fetchEntityMissingLogs = async ({
    info_id,
    entity_id,
    from_date,
    to_date,
  }: {
    entity_id: number
    info_id: number
    from_date?: string
    to_date?: string
  }): Promise<MissingCounterLog[]> => {
    const { data } = await this.fetchResource(`/api/missing-data/info/${info_id}/entities/${entity_id}/logs`, {
      from_date,
      to_date,
    })
    return data
  }

  // Create missing data entry
  createMissingDataFile = async (values: MissingDataLogs): Promise<AxiosResponse> => {
    const { data } = await this.postResource(`/api/missing-data/missing-entities-logs/`, values)
    return data
  }

  // update missing data entry
  updateMissingDataFile = async (id: number, values: MissingDataInfo): Promise<MissingDataInfo> => {
    const { data } = await this.patchResource(`/api/missing-data/info/${id}/`, values)
    return data
  }

  // update missing data entry
  updateMissingDataEntry = async ({
    info_id,
    entity_id,
    data,
  }: {
    entity_id: string
    info_id: string | number
    data: MissingCounterLog[]
  }): Promise<AxiosResponse> => {
    return this.putResource(`/api/missing-data/info/${info_id}/entities/${entity_id}/`, data)
  }

  // Delete missing data entry
  deleteMissingData = async (id: number): Promise<AxiosResponse> => {
    const { data } = await this.deleteResource(`/api/missing-data/info/${id}/`)
    return data
  }

  // Delete entity from a missing data entry
  deleteMissingDataEntity = async ({
    info_id,
    entity_id,
  }: {
    entity_id: number
    info_id: number
  }): Promise<AxiosResponse> => {
    const { data } = await this.deleteResource(`/api/missing-data/info/${info_id}/entities/${entity_id}/`)
    return data
  }

  /**  <--- Nodes --->  */

  fetchNodesList = async (params: { branch: number; search?: string }): Promise<Node[]> => {
    const { data } = await this.fetchResource(`/api/nodes/`, params)
    return data
  }

  updateNode = async (node: NodeUpdateRetrieve): Promise<NodeUpdateRetrieve> => {
    const { data } = await this.patchResource(`/api/nodes/${node.id}/`, node)
    return data
  }

  fetchNodeDownTimeAnalytics = async (
    id: number,
    params: { from_dt: string; to_dt: string }
  ): Promise<NodeDownTimeAnalytics> => {
    const { data } = await this.fetchResource(`/api/nodes/${id}/downtime-analytics/`, params)
    return data
  }

  /**  <--- Notifications --->  */

  fetchNotifications = async (params: {
    read?: boolean
    type?: string
    page: number
  }): Promise<PaginatedNotifications> => {
    const { data } = await this.fetchResource("/api/notifications/", params)
    return data
  }

  markAllAsRead = async (): Promise<AxiosResponse> => {
    return this.postResource("/api/notifications/mark_as_read/")
  }

  readNotification = async (id?: number): Promise<AxiosResponse> => {
    return this.postResource("/api/notifications/read/", {
      notifications: [id],
    })
  }

  fetchNotificationsCount = async (): Promise<NotificationCount> => {
    const { data } = await this.fetchResource("/api/notifications/unread_count/")
    return data
  }
  /**  <--- Permissions --->  */

  fetchPermissions = async (): Promise<Permission[]> => {
    const { data } = await this.fetchResource("/api/permissions/")
    return data
  }

  /**  <--- Regions --->  */
  fetchRegions = async (params?: { camera?: number; entity?: number }): Promise<Region[]> => {
    const { data } = await this.fetchResource("/api/regions/", params)
    return data
  }

  createRegion = async (region: Region): Promise<Region> => {
    const { data } = await this.postResource("/api/regions/", region)
    return data
  }

  deleteRegion = async (id: number): Promise<AxiosResponse> => {
    const { data } = await this.deleteResource(`/api/regions/${id}/`)
    return data
  }

  /**  <--- Security events --->  */
  fetchSecurityEventsAnalyticsLogs = async (params: {
    type?: string
    cameras?: string
    from_date: string
    to_date: string
    branch: number
  }): Promise<SecurityViolationAnalytics[]> => {
    const { data } = await this.fetchResource("/api/security-events/analytics/", params)
    return data
  }

  fetchSecurityEventsLogsPaginated = async ({
    limit = 6,
    ...params
  }: {
    from_date: string
    to_date: string
    branch: number
    limit?: number
    page: number
    type?: string
    cameras?: string
  }): Promise<PaginatedSecurityEvents> => {
    const { data } = await this.fetchResource("/api/security-events/logs/", { limit, ...params })
    return data
  }

  /**  <--- Shops --->  */

  fetchShops = async (params: { branch: number; camera?: number; ordering?: string }): Promise<Shop[]> => {
    const { data } = await this.fetchResource(`/api/shops/`, params)
    return data
  }

  addShop = async (values: Shop): Promise<Shop> => {
    const { data } = await this.postResource("/api/shops/", values)
    return data
  }

  updateShop = async (id: number, values: Shop): Promise<Shop> => {
    const { data } = await this.patchResource(`/api/shops/${id}/`, values)
    return data
  }

  deleteShop = async (id: number): Promise<AxiosResponse> => {
    const { data } = await this.deleteResource(`/api/shops/${id}/`)
    return data
  }

  fetchShopsTableLogs = async ({
    ordering = "-in_count",
    ...params
  }: {
    from_date: string
    to_date: string
    branch: number
    include_staff?: boolean
    ordering?: string
  }): Promise<ShopsAnalytics[]> => {
    const { data } = await this.fetchResource("/api/shops/analytics", {
      ordering,
      ...params,
    })
    return data
  }

  fetchCategoriesCount = async (params: {
    from_date: string
    to_date: string
    include_staff?: boolean
  }): Promise<CategoryCount[]> => {
    const { data } = await this.fetchResource("/api/shops/categories-count", params)
    return data
  }

  fetchCategoriesHourlyAvgCount = async ({
    category_id,
    ...params
  }: {
    from_date: string
    to_date: string
    include_staff?: boolean
    category_id: number
  }): Promise<HourlyAVGCounts[]> => {
    const { data } = await this.fetchResource(`/api/shops/categories/${category_id}/hourly-avg-counts`, {
      ...params,
    })
    return data
  }

  fetchShopsCounterStats = async (params: {
    from_date: string
    to_date: string
    entity_ids?: number
    date_slice: string
    include_staff?: boolean
    branch: number
    week_day?: number
  }): Promise<EntitiesLogs> => {
    const { data } = await this.fetchResource("/api/shops/counter-logs", params)
    return data
  }

  fetchDailyCategoriesCount = async (params: {
    from_date: string
    to_date: string
    include_staff?: boolean
  }): Promise<ShopsCategoryLogs[]> => {
    const { data } = await this.fetchResource("/api/shops/daily-categories-count", params)
    return data
  }

  fetchShopsDwelling = async (params: {
    from_date: string
    to_date: string
    entity_ids?: number
    date_slice: string
    include_staff?: boolean
    live?: boolean
  }): Promise<DwellingLogs[]> => {
    const { data } = await this.fetchResource("/api/shops/dwelling-logs", params)
    return data
  }

  fetchShopHourlyAvgCounts = async ({
    id,
    ...params
  }: {
    id: number
    from_date: string
    to_date: string
    include_staff?: boolean
  }): Promise<HourlyAVGCounts[]> => {
    const { data } = await this.fetchResource(`/api/shops/${id}/hourly-avg-counts`, {
      ...params,
    })
    return data
  }

  fetchShop = async (id: number): Promise<Shop> => {
    const { data } = await this.fetchResource(`/api/shops/${id}/`)
    return data
  }

  /**  <--- Support Email --->  */

  reportProblem = async (values: SupportEmailRequest): Promise<AxiosResponse> => {
    const { data } = await this.postResource("/api/support/email/", values)
    return data
  }

  /**  <--- Synopsis --->  */
  fetchPaginatedSynopsis = async (params: { page?: number; limit?: number }): Promise<PaginatedSynopsis> => {
    const { data } = await this.fetchResource("/api/synopsis/", params)
    return data
  }

  /**  <--- System --->  */

  fetchServices = async (): Promise<Module> => {
    const { data } = await this.fetchResource(`/api/system/services/`)
    return data
  }

  // fetch all services that has [overlap] with footage
  fetchServicesFootage = async ({ from_dt, to_dt }: { from_dt: string; to_dt: string }): Promise<ServiceLogsExist> => {
    const { data } = await this.fetchResource(`/api/system/services/logs-exist/?from_dt=${from_dt}&to_dt=${to_dt}`)
    return data
  }

  restartService = async (values: RestartRequest): Promise<Restart> => {
    const { data } = await this.postResource("/api/system/restart/", values)
    return data
  }

  restartAllServices = async (): Promise<RestartAll> => {
    const { data } = await this.postResource("/api/system/restart_all/")
    return data
  }

  /**  <--- User --->  */

  changeUserPassword = async (values: PasswordChange): Promise<PasswordChange> => {
    const { data } = await this.postResource("/api/user/change-password", values)
    return data
  }

  resetUserPassword = async (values: CustomPasswordReset): Promise<CustomPasswordReset> => {
    const { data } = await this.postResource("/api/user/password/reset/", values)
    return data
  }

  confirmResetPassword = async (
    token: string,
    uidb64: string,
    values: PasswordResetConfirm
  ): Promise<PasswordResetConfirm> => {
    const { data } = await this.postResource(`/api/user/password/reset/confirm/${uidb64}/${token}/`, values)
    return data
  }

  /**  <--- Users --->  */

  // get users
  fetchUsers = async (): Promise<UserList[]> => {
    const { data } = await this.fetchResource("/api/users/")
    return data
  }

  // get user
  fetchUser = async (id: number): Promise<UserRetrieve> => {
    const { data } = await this.fetchResource(`/api/users/${id}/`)
    return data
  }

  // add user
  addUser = async (values: UserCreateUpdate): Promise<UserCreateUpdate> => {
    const { data } = await this.postResource("/api/users/", values)
    return data
  }

  // update user
  updateUser = async (id: number, values: UserCreateUpdate): Promise<UserCreateUpdate> => {
    const { data } = await this.patchResource(`/api/users/${id}/`, values)
    return data
  }

  // delete user
  deleteUser = async (id: number): Promise<AxiosResponse> => {
    const { data } = await this.deleteResource(`/api/users/${id}/`)
    return data
  }
}

const api = new VisionAPI()

export { api as VisionAPI }
